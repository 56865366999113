import { useMemo } from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';
import { ChronosRun } from 'types';

import DateCell from './DateCell';

export const columnLabels: Record<string, string> = {};

const useGetDocumentColumns = () => {
  const columns = useMemo<ColumnDef<ChronosRun, any>[]>(
    () => [
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="name-tooltip"
              data-tooltip-content="Item Number"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="status-tooltip" style={TooltipStyles} />
            Run
          </div>
        ),
        size: 50,
        accessorKey: 'item_number',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { item_number },
            },
          },
        }: any) => <div className="pl-2 flex flex-row items-center justify-start w-10">{item_number + 1}</div>,
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="name-tooltip"
              data-tooltip-content="Status"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="status-tooltip" style={TooltipStyles} />
            Status
          </div>
        ),
        accessorKey: 'pipeline_status',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { pipeline_status, in_queue, queue_position },
            },
          },
        }: any) => (
          <div className="w-44">
            {in_queue ? `In queue, position ${queue_position}` : PIPELINE_STATUS[pipeline_status]?.label}
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <ReactInfo
              data-tooltip-id="name-tooltip"
              data-tooltip-content="Estimated Time"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            <Tooltip id="estimated-time-tooltip" style={TooltipStyles} />
            Estimated end Time
          </div>
        ),
        accessorKey: 'estimate_date',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { time_estimate_datetime },
            },
          },
        }: any) => <DateCell format={'DD MMMM YYYY'} date={time_estimate_datetime} />,
      },
    ],

    [],
  );
  return columns;
};

export default useGetDocumentColumns;
