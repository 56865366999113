import { rankItem } from '@tanstack/match-sorter-utils';
import { FilterFn } from '@tanstack/react-table';
import { ChronosFact, FilterOption } from 'types';

export interface FilterFns {
  stringMatch: FilterFn<ChronosFact>;
  fuzzyFilter: FilterFn<ChronosFact>;
  multiSelectDoc: FilterFn<ChronosFact>;
}

const filterFns: FilterFns = {
  stringMatch: (row, columnId, filterValue: FilterOption[]) => {
    if (!filterValue.length) return true;

    return filterValue.map((values) => values.value).includes(row.getValue(columnId));
  },
  fuzzyFilter: (row, columnId, filterValue: string, addMeta) => {
    if (!filterValue.length) return true;
    const itemRank = rankItem(row.getValue(columnId), filterValue);

    addMeta({
      itemRank,
    });

    const wordsArray = filterValue.split(' ').filter((word) => word.trim() !== '');
    const cellValue = (row.getValue(columnId) as string) || '';
    const isWordPresent = wordsArray.every((word) => cellValue.toLowerCase().includes(word.toLowerCase()));
    return isWordPresent;
  },
  multiSelectDoc: (row, columnId, filterValue: FilterOption[]) => {
    if (!filterValue.length) return true;

    const rowValue = row.getValue<string>(columnId);
    const docIdsInRow = rowValue.split(',');

    const isMatch = filterValue.reduce((accum, curr) => {
      if (docIdsInRow.includes(curr.value)) {
        return true;
      }
      return accum;
    }, false);

    return isMatch;
  },
};

export default filterFns;
