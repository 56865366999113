import React from 'react';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StageSpinner } from 'react-spinners-kit';

interface SearchBoxProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onSearchCall: () => void;
  loading?: boolean;
  clearSearch?: () => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ value, onChange, placeholder, onSearchCall, loading, clearSearch }) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearchCall();
    }
  };
  return (
    <div className="relative bg-white flex flex-row items-center justify-center rounded-md border border-gray-300">
      <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-4 text-gray-400" />
      <input
        className="rounded-md h-12 pl-10 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 bg-white"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder || 'Search cases'}
        onKeyDown={onKeyDown}
      />
      <button
        className={`exploreButton rounded-md cursor-pointer flex justify-center items-center min-w-20 px-4 my-1 mr-1 bg-blue-600 hover:bg-blue-500 disabled:bg-gray-500 font-semibold text-sm text-white h-10`}
        onClick={onSearchCall}
      >
        {loading ? <StageSpinner className="m- h-8" size={25} color={'#FFFFFF'} /> : 'Search'}
      </button>
      {clearSearch && (
        <button
          className="text-blue-600 not-italic font-bold cursor-pointer mx-1  px-4 h-10 rounded-md bg-"
          onClick={clearSearch}
        >
          {loading ? <StageSpinner className="m- h-8" size={25} color={'#FFFFFF'} /> : 'Clear'}
        </button>
      )}
    </div>
  );
};

export default React.memo(SearchBox);
