import React, { useState, useEffect, useRef } from 'react';

import {
  faCopy,
  faCheck,
  faHashtag,
  faFile,
  faMagnifyingGlass,
  faTimeline,
  faChartSimple,
  faTableList,
  faArrowUpRightFromSquare,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Accordion from 'components/molecules/Accordian';
// import ExpandableButton from 'components/molecules/ExpandableButton';
import { useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Tour from 'reactour';
import { ChronosFact } from 'types';

import ActionButton from './components/ActionButton';
import FactsTable from './components/KeyFactsTable';
import RelevantDocs from './components/RelevantDocs';
import StatsTable from './components/StatsTable';
import ThemeContent from './components/ThemeContent';
import formatSummary from './helpers/formatHTML';
import useGetCaseOverview from './hooks/useGetCaseOverview';
import useGetFactColumns from './hooks/useGetFactColumns';
import useGetSummary from './hooks/useGetSummary';

const CaseSummary = ({ outerRef }: any) => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  // State
  const keyFactsTableRef = useRef<HTMLDivElement>(null);
  const [themeTitles, setThemeTitles] = useState<{ title: string; message_id: string }[]>([]);
  const [themeText, setThemeText] = useState<string[]>([]);
  const [themeSubquestions, setThemeSubQuestions] = useState<{
    [message_id: string]: { title: string; message_content: string }[];
  }>({});

  const [copyClicked, setCopyClicked] = useState(false);

  // Hooks
  const { data: summaries, isLoading: summaryIsLoading, refetch: refetchSummary } = useGetSummary(caseId);
  const { data: overview, isLoading: overviewIsLoading, refetch: refetchOverview } = useGetCaseOverview(caseId);
  const { fetchConfigGET } = useGetFetchConfig();
  const keyFactColumns = useGetFactColumns();
  const location = useLocation();
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    refetchSummary();
    refetchOverview();
  }, [caseId, refetchSummary, refetchOverview]);

  useEffect(() => {
    if (!summaries) return;

    const themeTitles = summaries.summaries.map((summary: any) => {
      return {
        title: summary.title,
        message_id: summary.message_id,
      };
    });
    setThemeTitles(themeTitles);

    // Process summary text
    const themeTexts = summaries.summaries.map((summary: any) => {
      let summaryText = summary.message_content;
      // Remove anything between <b> tags
      summaryText = summaryText.replace(/<b>.*?<\/b>/g, '');
      // Replace all <br> tags with a space
      summaryText = summaryText.replace(/<br>/g, ' ').trim();
      return summaryText;
    });
    setThemeText(themeTexts);

    setThemeSubQuestions(summaries.subQuestions);
  }, [summaries]);

  // Handlers
  const scrollToRow = (id: string) => {
    const element = document.getElementById(id);

    if (element && keyFactsTableRef.current && outerRef.current) {
      // First scroll to the table
      const afterTable = document.getElementById('after-key-facts-table');
      outerRef.current.scrollTo({
        top: afterTable?.offsetTop || 1800,
        behavior: 'smooth',
      });

      // After a delay to allow the table to scroll into view, scroll to the row
      setTimeout(() => {
        if (keyFactsTableRef.current) {
          // elementOffset is the distance from the top of the table to the top of the row
          const rowOffset = element.offsetTop;

          keyFactsTableRef.current.scrollTo({
            top: rowOffset - 48,
            behavior: 'smooth',
          });
          element.classList.add('relative', 'z-30', 'animate-pulse-border-blue');
          setTimeout(() => {
            element.classList.remove('animate-pulse-border-blue', 'z-30', 'relative');
          }, 2000); // How long we pulse for
        }
      }, 400); // Delay to scroll to table
    }
  };

  const handleViewKeyFacts = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');

    const factIds = summaries?.keyFacts.map((fact: ChronosFact) => fact.event_id).join(',');
    searchParams.set('keyFactIds', factIds || '');

    navigate('/app/chronos/case-editor/facts?' + searchParams.toString());
  };

  const handleViewFacts = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/facts?' + searchParams.toString());
  };

  const handleViewDocs = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/documents?' + searchParams.toString());
  };

  const handleViewSingleFact = async (factId: string) => {
    // Work out what page that fact is going to be on
    const resp = await fetch(`${APIBaseChronos}/client/case/fact/${caseId}/pageNumber/${factId}`, fetchConfigGET);
    const data = await resp.json();
    const rowNumber = data.rowNumber;
    //TODO Change this to a config page number
    const page = Math.ceil(rowNumber / 50) - 1;

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page.toString());
    searchParams.set('keyFactId', factId);
    navigate('/app/chronos/case-editor/facts?' + searchParams.toString());
  };

  const handleRelevantDocClick = (docId: string) => {
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('caseId');
    if (!caseId) return;
    searchParams.set('caseId', caseId);
    searchParams.set('docId', docId);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/documents?' + searchParams.toString());
  };

  const handleCopyClick = () => {
    // const text = content
    //   .replace(/\[.*?\]/g, '')
    //   .replace(/<b>/g, '')
    //   .replace(/<\/b>/g, '')
    //   .replace(/<br>/g, '\n');
    // navigator.clipboard.writeText(text);

    setCopyClicked(true);
    setTimeout(() => {
      setCopyClicked(false);
    }, 2000);
  };

  const [themeTourOpen, setThemeTourOpen] = useState(false);
  const TOUR_STYLES = {
    backgroundColor: 'rgb(249 250 251)',
    borderRadius: '8px',
  };

  const steps = [
    {
      selector: '#themes',
      content: () => (
        <div className="text-gray-700 bg-gray-50">
          Wexler extracts key themes across your documents which you can analyse!
        </div>
      ),
      style: TOUR_STYLES,
    },
    {
      selector: '#theme-0',
      content: 'Select a theme to view its content.',
      action: (node: any) => {
        node.click();
      },
      style: TOUR_STYLES,
    },

    {
      selector: '#theme-0-text',
      content: 'Read a summary of that theme and its relevance to the case.',
      style: TOUR_STYLES,
    },
    {
      selector: '#theme-0-subquestion-0',
      content: 'Explore further questions and their associated answers.',
      style: TOUR_STYLES,
    },
  ];

  return (
    <div className="flex flex-col pb-4 leading-relaxed">
      {summaryIsLoading || overviewIsLoading ? (
        <div className="flex justify-center items-center h-64">
          <StageSpinner className="m-auto" color={'rgb(37 99 235)'} />
        </div>
      ) : (
        <>
          {themeTitles.length && themeTitles.length && (
            <>
              <div className="flex mb-8">
                <div className="rounded-md bg-gray-50 border-2 border-gray-200 border-opacity-60 p-4">
                  <div className="text-lg font-semibold mb-2">Summary</div>
                  <div className="">{formatSummary(themeText[0], scrollToRow)}</div>
                  <div className="text-gray-800 flex gap-2 mt-4">
                    <div
                      onClick={handleCopyClick}
                      className="flex items-center gap-2 border hover:bg-gray-200 hover:cursor-pointer rounded-full px-3 py-2"
                    >
                      {copyClicked ? (
                        <>
                          <FontAwesomeIcon icon={faCheck} className="w-4 h-4 cursor-pointer" />
                          <span className="text-xs">Copied!</span>
                        </>
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faCopy} className="w-4 h-4 cursor-pointer" />
                          <span className="text-xs">Copy</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-between w-full gap-8 mb-10">
                <div className="relative bg-gray-50 p-4 border-2 border-gray-200 border-opacity-40 rounded-lg py-4 w-5/12">
                  <h2 className=" title-font font-medium text-lg mb-2 border-2 border-x-0 border-t-0 pb-1">Explore</h2>
                  <div className="text-sm text-gray-800 flex flex-col gap-2 w-full ">
                    <ActionButton
                      icon={faHashtag}
                      title="Analyse key themes"
                      description="Examine key themes extracted from the case facts."
                      onClick={() => setThemeTourOpen(true)}
                      borderActive
                    />
                    <ActionButton
                      icon={faTimeline}
                      title="Create chronology"
                      description="Construct a timeline of key events."
                      onClick={() => {}}
                    />
                    <ActionButton
                      icon={faMagnifyingGlass}
                      title="Explore the facts"
                      description="Use our agent, Kim, to search and interrogate your documents."
                      onClick={() => {}}
                    />
                  </div>
                </div>

                <div className="relative bg-gray-50 p-4 border-2 border-gray-200 border-opacity-60 rounded-lg py-4 w-3/4">
                  <h2 className=" title-font font-medium text-lg mb-4 border-2 border-x-0 border-t-0 pb-1">Overview</h2>
                  <div className="flex gap-4 w-full text-sm text-gray-600 px-1">
                    <div className="flex flex-col gap-4 w-1/3 border-2 border-y-0 border-l-0 pr-10">
                      <p className="text-base font-semibold border border-x-0 border-t-0">
                        <FontAwesomeIcon icon={faChartSimple} className="text-gray-800 w-4 h-4 mr-2" />
                        Totals
                      </p>
                      <StatsTable
                        stats={{
                          factsExtracted: overview?.caseOverview?.totalFacts || 0,
                          relevantFacts: overview?.caseOverview?.relevantFacts || 0,
                          // TODO Change this so this also fetches from overview endpoint
                          keyFacts: summaries?.keyFacts.length || 0,
                          documents: overview?.caseOverview?.documents || 0,
                          pages: overview?.caseOverview?.pages || 0,
                        }}
                        onClickHandlers={{
                          factsExtracted: handleViewFacts,
                          relevantFacts: handleViewFacts,
                          keyFacts: handleViewKeyFacts,
                          documents: handleViewDocs,
                          pages: handleViewDocs,
                        }}
                        tooltips={{
                          factsExtracted: 'Total number of facts extracted from the documents.',
                          relevantFacts: 'Number of facts deemed relevant to the case synopsis.',
                          keyFacts: 'Number of key facts extracted from the documents.',
                          documents: 'Total number of documents in the case.',
                          pages: 'Total number of pages across the documents in the case.',
                        }}
                      />
                    </div>

                    <div className="text-sm flex flex-col gap-4 w-2/3 pl-4">
                      <p className="text-base font-semibold border border-x-0 border-t-0 flex items-center">
                        <FontAwesomeIcon icon={faFile} className="text-gray-800 w-4 h-4 mr-2" />
                        Most Relevant Documents
                        <FontAwesomeIcon
                          data-tooltip-id="relevant-docs"
                          data-tooltip-content="The top 3 most relevant documents based on the number of relevant facts extracted from them."
                          icon={faInfoCircle}
                          className="text-gray-400 w-3 h-3 cursor-pointer ml-2"
                        />
                        <Tooltip
                          id="relevant-docs"
                          opacity={1}
                          style={{
                            width: '200px',
                            textAlign: 'center',
                            padding: '8px',
                            borderRadius: '8px',
                            backgroundColor: 'white',
                            color: 'black',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            letterSpacing: '-0.3px',
                            zIndex: 1,
                          }}
                        />
                      </p>
                      <RelevantDocs docs={overview?.caseOverview?.docEvents} onClick={handleRelevantDocClick} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-8 bg-gray-50 p-4 border-2 border-gray-200 border-opacity-60 rounded-lg">
                <div id="themes" className="text-lg font-semibold mb-2 flex items-center ">
                  <FontAwesomeIcon icon={faHashtag} className="text-gray-800 w-5 h-5 cursor-pointer mr-2" />
                  Themes
                </div>
                <div className="flex flex-col border-2 border-gray-200 border-opacity-60 rounded-md">
                  {themeTitles.slice(1).map((themeTitle, index) => (
                    <Accordion
                      id={`theme-${index}`}
                      key={index}
                      index={index}
                      title={<p className="text-blue-500 pr-12">{themeTitle.title}</p>}
                      content={
                        <ThemeContent
                          id={`theme-${index}`}
                          subquestions={themeSubquestions[themeTitle.message_id]}
                          content={formatSummary(themeText[index + 1], scrollToRow)}
                        />
                      }
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          {summaries && summaries.keyFacts && summaries.keyFacts.length > 0 && (
            <div className="mb-4 bg-gray-50 p-4 border-2 border-gray-200 border-opacity-60 rounded-lg">
              <div className="flex items-center gap-1 w-full mb-3">
                <div className="text-lg font-semibold flex items-center">
                  <FontAwesomeIcon icon={faTableList} className="text-gray-800 w-5 h-5 cursor-pointer mr-2 " />
                  Key Facts
                </div>

                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className="text-blue-500 w-4 h-4 cursor-pointer p-2 hover:bg-gray-100 rounded-full"
                  onClick={handleViewKeyFacts}
                />
              </div>

              <div
                ref={keyFactsTableRef}
                className="rounded-lg border-2 border-gray-200 border-opacity-60 max-h-[32rem] overflow-y-scroll overflow-x-visible"
              >
                <FactsTable
                  data={summaries.keyFacts}
                  columns={keyFactColumns}
                  handleViewSingleFact={handleViewSingleFact}
                />
              </div>
            </div>
          )}
          <div id="after-key-facts-table"></div>
        </>
      )}
      <Tour
        steps={steps}
        isOpen={themeTourOpen}
        onRequestClose={() => setThemeTourOpen(false)}
        onBeforeClose={
          // Close the theme if it is open
          () => {}
        }
      />
    </div>
  );
};

export default CaseSummary;
