import { memo, useCallback, useRef, useState } from 'react';

import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosChronology } from 'types';

type DeleteResponseType = Response & {
  success: boolean;
  notCreator: boolean;
};

interface ChronologyListItemProps {
  chronology: ChronosChronology;
  setChronologiesListDeleteMap: (
    newState: Record<string, boolean> | ((prev: Record<string, boolean>) => Record<string, boolean>),
  ) => void;
  chronologiesListDeleteMap: Record<string, boolean>;
}

const ChronologyListItem = memo(
  ({ chronology, setChronologiesListDeleteMap, chronologiesListDeleteMap }: ChronologyListItemProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentName, setCurrentName] = useState(chronology.latest_title || '');
    const { getFetchConfig } = useGetFetchConfig();
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
      if (ref.current) {
        ref.current.style.height = '38px'; // Reset the height to its initial value
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
    };
    const fetchConfig = getFetchConfig({
      method: 'PUT',
      data: {
        title: currentName,
      },
    });

    const { isFetching: isLoadingUpdateChronologyName, refetch: refetchUpdateChronologyName } = useQuery(
      ['updateFieldValue', chronology.chronology_id],
      () => {
        return fetch(`${APIBaseChronos}/client/case/chronology/byChronologyId/${chronology.chronology_id}`, fetchConfig)
          .then((res) => {
            setEditMode(false);
            return res.json();
          })
          .catch((err) => {
            console.error('Fetch Error: ', err);
            Swal.fire({
              title: 'Error on update',
              text: 'There was an error on updating the chronology name. Please try again later.',
              showConfirmButton: false,
              timer: 3000,
            });
          });
      },
      {
        cacheTime: 0,
        enabled: false,
      },
    );

    const onChronologyClick = useCallback(() => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('page', '1');
      searchParams.set('chronologyId', chronology.chronology_id);
      navigate('/app/chronos/case-editor/chronology?' + searchParams.toString(), { replace: true });

      // eslint-disable-next-line
    }, [chronology.chronology_id]);

    const onDeleteClick = useCallback(() => {
      Swal.fire({
        title: '',
        text: 'Deleting chronologies is irreversible. Do you want to proceed?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
        timer: 10000,
        confirmButtonColor: 'red',
      }).then((result: any) => {
        if (result.isConfirmed) {
          setIsDeleting(true);
          const fetchConfig = getFetchConfig({
            method: 'DELETE',
          });
          fetch(`${APIBaseChronos}/client/case/chronology/byChronologyId/${chronology.chronology_id}`, fetchConfig)
            .then((res) => {
              return res.json();
            })
            .then((resJson) => {
              const resJsonCasted = resJson as DeleteResponseType;
              if (resJson.success) {
                Swal.fire({
                  title: '',
                  text: 'Chronology deleted successfully',
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 3000,
                });
                setChronologiesListDeleteMap((prev: any) => ({ ...prev, [chronology.chronology_id]: true }));
              } else if (resJsonCasted.notCreator) {
                Swal.fire({
                  title: 'Not authorized',
                  text: 'You are not the creator of this chronology',
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 3000,
                });
                setIsDeleting(false);
              } else {
                Swal.fire({
                  title: '',
                  text: 'Error deleting chronology. Try again later',
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 3000,
                });
                setIsDeleting(false);
              }
            })
            .catch((err) => {
              console.error('Fetch Error: ', err);
              Swal.fire({
                title: 'Error on delete',
                text: 'There was an error on deleting the chronology. Please try again later.',
                showConfirmButton: false,
                timer: 3000,
              });
              setIsDeleting(false);
            });
        }
      });
      // eslint-disable-next-line
    }, [chronology.chronology_id]);

    const togleEditMode = () => {
      if (!editMode) {
        setEditMode(true);
        setTimeout(() => {
          autoResize(textAreaRef);
        }, 0);
      } else {
        refetchUpdateChronologyName();
      }
    };

    const handleChangeFieldValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCurrentName(e.target.value);
      autoResize(textAreaRef);
    };

    if (chronologiesListDeleteMap[chronology.chronology_id]) {
      return null;
    }

    return isDeleting ? (
      <div className="bg-white w-full rounded-lg p-8 flex items-center justify-center flex-col mb-3 h-24">
        <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
      </div>
    ) : (
      <div
        className="bg-white w-full rounded-lg p-8 flex items-start justify-start flex-col mb-3"
        key={chronology.chronology_id}
      >
        {isLoadingUpdateChronologyName ? (
          <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
        ) : editMode === true ? (
          <div className="flex flex-row items-center w-full">
            <textarea
              ref={textAreaRef}
              className={`rounded-md pl-2 resize-none overflow-hidden w-full`}
              onChange={handleChangeFieldValue}
              value={currentName}
              style={{
                outline: 'none',
                border: '1px solid rgba(0,0,0,0.1)',
                minWidth: '100px',
                width: '100%',
                height: '38px',
              }}
            />
            <FontAwesomeIcon onClick={togleEditMode} icon={faCheck} className="text-gray-700 ml-2 cursor-pointer" />
          </div>
        ) : (
          <div
            className="text-xl not-italic font-semibold mt-3 cursor-pointer flex flex-row items-center"
            style={{ color: 'var(--black-100, #1C1C1C)' }}
          >
            <div onClick={onChronologyClick}>{currentName}</div>
            <FontAwesomeIcon
              onClick={togleEditMode}
              icon={faPencil}
              className="text-gray-700 ml-2 cursor-pointer"
              aria-label="edit"
            />
          </div>
        )}
        <div className="overflow-hidden text-green-700 text-sm not-italic font-semibold leading-5 right-0 flex justify-between items-center mt-2">
          Date: {`${moment(chronology.created_date || Date.now()).format('MMMM DD, YYYY')}`}
        </div>
        <div className="flex flex-row w-full mt-5">
          <button
            className={`exploreButton cursor-pointer flex justify-center items-center min-w-20 px-4 h-8 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 font-semibold text-sm text-white`}
            onClick={onChronologyClick}
          >
            View
          </button>
          <button
            className="cursor-pointer text-xs font-semibold flex ml-5 items-center rounded-lg text-red-600 bg-red-200 h-8 px-4"
            onClick={onDeleteClick}
          >
            Delete chronology
          </button>
          <div
            className="text-sm not-italic font-normal flex items-start bg-yellow-200 ml-auto"
            style={{ color: 'rgba(0, 0, 0, 0.80)', padding: '8px 15px', borderRadius: '40px' }}
          >
            {`Last updated by ${chronology.editor_email} on ${moment(
              chronology.ultimate_edit_date || chronology.created_date,
            ).format('DD/MM/YYYY HH:mm')}`}
          </div>
        </div>
      </div>
    );
  },
);

export default ChronologyListItem;
