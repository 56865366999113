import { MutableRefObject, useCallback, useMemo } from 'react';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from '@tanstack/react-table';
import { ReactComponent as ExcelIcon } from 'assets/Excel.svg';
import Button from 'components/atoms/Button';
import { StageSpinner } from 'react-spinners-kit';

import { ChronosChronology, ChronosChronologyEvent } from '../../../../../types';
import Pagination, { PaginationProps } from '../../../Pagination';
import SearchBox from '../../components/SearchBox';
import ShowHideColumns from '../../ShowHideColumns';
import { chronologyColumnLabels } from '../useGetChronologyColumns';

interface ChronologyTableToolbarProps {
  globalFilter: string;
  chronologyId: string;
  isLoadingDownload: boolean;
  viewDownloadOptions: boolean;
  chronologies: ChronosChronology[];
  wrapperRef: MutableRefObject<HTMLDivElement | null>;
  setGlobalFilter: (value: string) => void;
  toggleViewDownloadOptions: () => void;
  fetchDownload: (docType: string) => void;
  handleGoToChronologiesList: () => void;
  resetVisibleColumns: () => void;
  columns: Column<ChronosChronologyEvent>[];
  openModal: () => void;
  isLoadingFacts: boolean;
  goToPage: (value: number) => void;
  clearAllFilters: () => void;
  onSearchCall: () => void;
  isDataDirty: boolean;
  refetchChronology: () => void;
}

const ChronologyTableToolbar = ({
  columns,
  globalFilter,
  setGlobalFilter,
  chronologyId,
  chronologies,
  wrapperRef,
  isLoadingDownload,
  viewDownloadOptions,
  fetchDownload,
  toggleViewDownloadOptions,
  handleGoToChronologiesList,
  resetVisibleColumns,
  openModal,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  isLoadingFacts,
  goToPage,
  clearAllFilters,
  onSearchCall,
  isDataDirty,
  refetchChronology,
}: ChronologyTableToolbarProps & PaginationProps) => {
  const currentChronologyTitle = useMemo(() => {
    return chronologies.find((chronology) => chronology.chronology_id === chronologyId)?.latest_title;
  }, [chronologies, chronologyId]);

  const handleDownloadClick = useCallback(() => {
    fetchDownload('xlsx');
  }, [fetchDownload]);

  return (
    <div>
      <div className="flex flex-row items-center mt-3">
        <div onClick={handleGoToChronologiesList} className="text-blue-500">
          Chronologies List
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-gray-700 mx-2 cursor-pointer"
          style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
        />
        <div className="text-blue-500 font-semibold">{currentChronologyTitle}</div>
      </div>
      <div className="mb-3 mt-4 flex flex-row items-center justify-between">
        <Button size="small" type="secondary" onClick={openModal} text="Add Fact" />

        <div className="relative ml-4 mr-auto" ref={wrapperRef}>
          {isLoadingDownload ? (
            <StageSpinner size={25} className="m-auto h-4" color={'#4161FF'} />
          ) : (
            <Button size="small" type="secondary" onClick={toggleViewDownloadOptions} text="Download" />
          )}

          {viewDownloadOptions && (
            <div
              className="absolute left-11 top-8 bg-white w-72 rounded-2xl"
              style={{ zIndex: 1000, boxShadow: '0px 4px 28px 0px rgba(0, 0, 0, 0.15)' }}
            >
              <div className="flex flex-col w-full cursor-pointer">
                <div
                  className="w-full  p-4 hover:bg-slate-100  rounded-t-2xl flex flex-row items-center"
                  onClick={handleDownloadClick}
                >
                  <ExcelIcon className="mr-4" /> Download Excel
                </div>
              </div>
            </div>
          )}
        </div>

        <button
          className="text-gray-900 not-italic font-bold leading-6 ml-auto mr-5 cursor-pointer border p-2 rounded hover:bg-gray-100"
          onClick={clearAllFilters}
        >
          Clear filters
        </button>
        <SearchBox
          value={globalFilter}
          placeholder={'Search chronology'}
          onChange={setGlobalFilter}
          onSearchCall={onSearchCall}
        />
      </div>

      <div className="flex flex-row mb-4 items-center gap-4 justify-end">
        {isDataDirty && (
          <div className="flex justify-center">
            <button
              className="mr-4 flex justify-center items-center px-4 py-3 rounded-md font-semibold cursor-pointer transition-transform transform scale-0 animate-popIn"
              style={{
                backgroundColor: '#ECEFFF',
                color: '#4161FF',
              }}
              onClick={() => {
                refetchChronology();
              }}
            >
              Update Table
            </button>
          </div>
        )}

        <ShowHideColumns
          columns={columns}
          resetVisibleColumns={resetVisibleColumns}
          columnLabels={chronologyColumnLabels}
        />

        <Pagination
          canGetPrevPage={canGetPrevPage}
          canGetNextPage={canGetNextPage}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          noOfPages={noOfPages}
          isLoadingFacts={isLoadingFacts}
          goToPage={goToPage}
        />
      </div>
    </div>
  );
};

export default ChronologyTableToolbar;
