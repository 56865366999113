import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ExpandableButtonProps {
  icon: any;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  isActive?: boolean; // Add this to handle initial expand
}

const ExpandableButton: React.FC<ExpandableButtonProps> = ({
  icon,
  children,
  className,
  onClick,
  isActive = false,
}) => {
  const [hover, setHover] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current && textRef.current) {
      if (hover) {
        buttonRef.current.style.width = `${textRef.current.scrollWidth + 50}px`;
      } else {
        buttonRef.current.style.width = '40px';
      }
    }
  }, [hover]);

  // Apply the hover effect based on isActive at mount
  useEffect(() => {
    setHover(isActive);
    if (isActive) {
      const element = buttonRef.current;
      if (!element) return;
      element.classList.add('animate-pulse-border-gold');
      element.classList.add('border-yellow-500');
      const timeout = setTimeout(() => {
        setHover(false);
        element.classList.remove('animate-pulse-border-gold');
        element.classList.remove('border-yellow-500');
      }, 3000); // Change this duration as needed
      return () => clearTimeout(timeout);
    }
  }, [isActive]);

  const DEFAULT_STYLES =
    'py-2 flex items-center rounded bg-[#ECEFFF] text-blue-600 transition-all duration-300 ease-in-out overflow-hidden w-10 justify-center';

  // Conditionally apply the pulse-border animation based on isActive
  const buttonStyles = `${className ? className : DEFAULT_STYLES}`;

  return (
    <button
      ref={buttonRef}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={buttonStyles}
    >
      <FontAwesomeIcon icon={icon} className="mx-1" />
      <span
        ref={textRef}
        className={`whitespace-nowrap overflow-hidden transition-all duration-300 ease-linear ${
          hover ? 'w-auto opacity-100 ml-1' : 'w-0 opacity-0'
        }`}
        style={{
          transition: 'width 0.3s ease, opacity 0.3s ease 0.1s',
        }}
      >
        {children}
      </span>
    </button>
  );
};

export default ExpandableButton;
