import styled from 'styled-components';

export const ContainerDatePicker = styled.button`
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 2px !important;
  }

  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
    border-radius: 2px !important;
  }

  .react-datepicker__input-container input {
    width: 100%;
    height: 100%;
    color: black !important;
    padding-left: 3px;
    border-radius: 2px !important;
  }
`;
