import React, { useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import moment from 'moment';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import 'react-toggle/style.css';
import Swal from 'sweetalert2';

import { CheckState } from '../../../../../types';

export type SetCheckedRows = React.Dispatch<React.SetStateAction<Record<string, CheckState>>>;

interface VerifyCellProps {
  editable?: boolean;
  verified_by_email: string;
  verified_date: Date;
  line_id: string;
  verified: boolean;
  setCheckedRows: SetCheckedRows;
  editor_id_email: string;
  callback?: () => void;
}
const VerifyCell = ({
  verified: currentVerified,
  verified_by_email,
  verified_date,
  line_id,
  setCheckedRows,
  editor_id_email,
  callback,
}: VerifyCellProps) => {
  const [currentVerifiedByEmail, setCurrentVerifiedByEmail] = useState(verified_by_email);
  const [currentVerifiedByDate, setCurrentVerifiedByDate] = useState<Date>(verified_date);
  const { getFetchConfig } = useGetFetchConfig();
  const { isFetching: isFetchingUpdateVerified, refetch: refetchUpdateVerified } = useQuery(
    [`updateFieldValue`, line_id, currentVerified, verified_by_email, verified_by_email, editor_id_email],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          verified: true,
        },
      });

      return fetch(`${APIBaseChronos}/client/case/chronology/${line_id}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setCurrentVerifiedByEmail('you');
          setCurrentVerifiedByDate(res.verified_date);
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error on update',
            text: 'There was an error on updating the fields. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const { isFetching: isFetchingUpdateUnverified, refetch: refetchUpdateUnverified } = useQuery(
    [`updateFieldValue`, line_id, currentVerified, verified_by_email, verified_by_email, editor_id_email],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          verified: false,
        },
      });

      return fetch(`${APIBaseChronos}/client/case/chronology/${line_id}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          setCurrentVerifiedByEmail('you');
          setCurrentVerifiedByDate(res.verified_date);
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error on update',
            text: 'There was an error on updating the fields. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleVerifyFact = () => {
    setCheckedRows((prev) => ({
      ...prev,
      [line_id]: {
        isIncluded: prev[line_id].isIncluded,
        isVerified: true,
        verified_by_email: 'you',
        verified_date: new Date(Date.now()),
        first_comment: prev[line_id].first_comment,
        comments_count: prev[line_id].comments_count,
      },
    }));
    refetchUpdateVerified();
    callback && callback();
  };

  const handleUnverifyFact = () => {
    setCheckedRows((prev) => ({
      ...prev,
      [line_id]: {
        isIncluded: prev[line_id].isIncluded,
        isVerified: false,
        verified_by_email: 'you',
        verified_date: new Date(Date.now()),
        first_comment: prev[line_id].first_comment,
        comments_count: prev[line_id].comments_count,
      },
    }));
    refetchUpdateUnverified();
    callback && callback();
  };

  return (
    <div className="flex flex-row justify-center items-center w-full px-3">
      {currentVerified ? (
        <div className="flex flex-col items-center justify-center mx-auto">
          <div className="flex flex-col items-center justify-center text-center">
            {currentVerifiedByEmail ? `Verified by ${currentVerifiedByEmail}` : 'Verified'}
          </div>
          {currentVerifiedByDate && (
            <div
              className="text-xs not-italic font-normal "
              style={{ color: 'var(--colors-primary-slate-500, #5E718D)' }}
            >
              {currentVerifiedByDate ? moment(currentVerifiedByDate).format('MMM DD, YYYY') : '-'}
            </div>
          )}
          <div
            className="cursor-pointer flex justify-center items-center rounded-lg mx-auto not-italic font-bold  h-10 px-3 shrink-0 w-44 text-red-600 bg-red-200 my-2"
            onClick={handleUnverifyFact}
          >
            {isFetchingUpdateUnverified ? <StageSpinner className="m-auto" size={25} color={'red'} /> : 'Unverify'}
          </div>
        </div>
      ) : (
        <div
          className="cursor-pointer flex justify-center items-center rounded-lg mx-auto not-italic font-bold  h-10 px-3 shrink-0 w-44"
          style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
          onClick={handleVerifyFact}
        >
          {isFetchingUpdateVerified ? <StageSpinner className="m-auto" size={25} color={'#4161FF'} /> : 'Verify'}
        </div>
      )}
    </div>
  );
};

export default VerifyCell;
