import Background from 'assets/bg-explorer.png';
import CaseCreateSynopsis from 'assets/synopsis.png';

const CaseSynopsis = () => {
  return (
    <div className="font-sans flex flex-col items-start justify-start text-black h-screen overflow-auto">
      <div
        className="w-full h-[35vh] flex items-center"
        style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}
      >
        <h1 className="text-5xl not-italic font-semibold px-20 py-10">Case Synopsis</h1>
      </div>
      <div className="px-5 mb-5">
        <h2 className="text-2xl font-semibold mb-0.5 py-5" style={{ color: 'rgba(28, 28, 28, 0.60)' }}>
          Preparation of Case Synopsis - Wexler Chronos
        </h2>
      </div>
      <div className="px-5 mb-5">
        <span className="font-bold">
          Please find a list of issues below. Review the list and consider the issues. The below is guidance for how to
          draft a synopsis, you do not need to select the issues to create a case.
          <br />
          <br />
          Once reviewed, write a short description of each checked issue in the synopsis box below including the likely
          response of all other parties to each such issue. Condense this into a synopsis.
          <br />
          <br />
          By way of example we have ticked and filled in the synopsis box by reference to the case of{' '}
          <span className="font-italic">Guest v Guest</span>
          [2022] UKSC 27.
          <br />
          <br />
          NB: It should be fewer than ~250 words.
          <br />
          <br />
          Below shows where the synopsis section is found when creating a case
        </span>
        <div className="case-create-synopsis mt-10 mb-10 mr-5 ml-5">
          <img src={CaseCreateSynopsis} alt="Description of where a synopsis is located" />
        </div>
      </div>
      <div className="checklist mt-10">
        <h2 className="text-2xl mb-5 font-semibold ml-5" style={{ color: 'rgba(28, 28, 28, 0.60)' }}>
          Civil/Commercial
        </h2>
        <p className="font-bold mb-5 ml-5">Cause of action</p>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          Non-proprietary claim, specifying
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          breach of contract, including validity
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          breach of fiduciary duty
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          breach of duty of care, including statutory duty
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          breach of other duty
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          proprietary claim, specifying
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled checked />
          proprietary estoppel
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          constructive or resulting trust
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          possesion
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          other proprietary claim
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          corporate claim (specifying)
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          unfair prejudice
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          dissolution
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          other corporate claim
        </label>
        <p className=" font-bold mb-5 ml-5">Generally</p>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          seriously disptuted facts
        </label>
        <p className="font-bold mb-5 ml-5">Factual issues</p>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          nomineeship
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          fraud, specifying
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          deceit
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          misrepresentation
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          bribery
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          dishonest assistance
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          unconscionable receipt
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          unlawful means conspiracy
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          other form of dishonesty
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          voidness or voidability, specifying
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          duress (including undue influence)
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          mistake
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          incapacity (including other forms of vulnerability)
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          unconscionable bargain
        </label>
        <label className="block my-4 ml-40">
          <input type="checkbox" className="mr-6" disabled />
          other
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          vicarious liability
        </label>
        <p className="font-bold mb-5 ml-5">Legal issues</p>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled checked />
          substantive law
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          procedural law (including admissibility of evidence and relief from sanctions)
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          Foregin law
        </label>
        <p className="font-bold mb-5 ml-5">Relief</p>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          specific performance
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          damages
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          account of profits
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled checked />
          equitable monetary compensation
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled checked />
          declarations
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          permanent injunction
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          recission
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          interest
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          other (including corporate relief, specifying)
        </label>
        <p className="font-bold mb-5 ml-5">Likely consequential post-judgment issues</p>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled checked />
          costs
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          security
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          post-judgment injunctions
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled />
          other (please specify)
        </label>
        <label className="block my-4 ml-20">
          <input type="checkbox" className="mr-6" disabled checked />
          permission to appeal
        </label>
        <p className="font-bold mt-5 mb-5 ml-5">Synopsis</p>
        <p className="ml-10 mt-5 mr-10 mb-20 italic">
          The claimant is the son of the defendants. He claims that for many years his parents promised him that he
          would inherit a substantial share of their farming business and that he conducted himself to his detriment, in
          reliance on that promise, by working at an underpayment for many years on his parents' farm, The defendants
          entirely deny this claim stating that no such promise was ever made and that the claimant was fully and fairly
          remunerated for his work. The substantive law relating to proprietary estoppel is far from clear and there are
          many differences as to its impact between the parties. The claimant seeks a declaration as to his entitlement
          and the payment of monetary compensation to reflect his losses and the detriment suffered by him. It is highly
          likely that whatever decision is reached that there will be an appeal. There is also a very substantial issue
          as to the costs that have been incurred.
        </p>
      </div>
    </div>
  );
};

export default CaseSynopsis;
