import React from 'react';

import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip, PlacesType } from 'react-tooltip';

interface TooltipIconProps {
  tooltipId: string;
  tooltipContent: string;
  className?: string;
  style?: React.CSSProperties;
  place?: string;
}

const TooltipIcon: React.FC<TooltipIconProps> = React.memo(({ tooltipId, tooltipContent, className, style, place }) => (
  <>
    <ReactInfo
      data-tooltip-id={tooltipId}
      className={`text-gray-700 mr-2 text-sm cursor-pointer ${className}`}
      style={style}
    />
    <Tooltip id={tooltipId} content={tooltipContent} style={TooltipStyles} place={(place as PlacesType) || ''} />
  </>
));

export default TooltipIcon;
