import { useRef } from 'react';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeadersInstance, Row, RowModel, flexRender } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { StageSpinner } from 'react-spinners-kit';
import { ChronosChronologyEvent, FilterOption } from 'types';

import MultiselectFilter from '../../components/Filters/MultiselectFilter';
export const hiddenColumns = ['fact_date', 'included'];

interface ChronologyEditorTableProps {
  getHeaderGroups: HeadersInstance<ChronosChronologyEvent>['getHeaderGroups'];
  getRowModel: () => RowModel<ChronosChronologyEvent>;
  onRowReorder: DraggableRowProps['onRowReorder'];
  hasReorderError: boolean;
  refetchChronology: () => void;
  handleRemoveRow: (lineId: string) => void;
  clearAllFilters: () => void;
  hasActiveFilters: boolean;
  isLoadingChronologyTable: boolean;
}

export interface DraggableRowProps {
  row: Row<ChronosChronologyEvent>;
  onRowReorder: (params: { draggedRow: Row<ChronosChronologyEvent>; targetRowOrder: number }) => void;
  handleRemoveRow: (lineId: string) => void;
}

const DraggableRow = ({ row, onRowReorder, handleRemoveRow }: DraggableRowProps) => {
  const ref = useRef(null);
  const [{ isDragging }, dragRef, connectPreviewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: 'row',
  });

  const [{ isOver }, connectDropRef] = useDrop({
    accept: 'row',
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
    drop: (draggedRow: Row<ChronosChronologyEvent>) => {
      onRowReorder({ draggedRow, targetRowOrder: row.index });
    },
  });

  const rowStyle = isOver ? 'border-y-2' : '';
  connectDropRef(ref);
  connectPreviewRef(ref);

  const onRemoveRow = () => {
    handleRemoveRow(row.original.line_id);
  };

  return (
    <tr
      style={{
        background: row.index % 2 === 0 ? 'white' : '#F9FAFB',
        opacity: isDragging ? 0.6 : 1,
        display: row.original.is_hidden ? 'none' : '',
        borderBottom: '1px solid #c8cad7',
      }}
      className={rowStyle}
      ref={ref}
    >
      <td ref={dragRef} className="px-3 border-r">
        <div className="flex justify-center">
          <button>🟰</button>
        </div>
      </td>
      {row.getVisibleCells().map((cell) => {
        const cellStyle = cell.column.id === 'fact_date' ? { display: 'none' } : {};
        return (
          <td className="pl-3" style={{ ...cellStyle, maxWidth: cell.column.getSize() }} key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
      <td className="px-3 w-5 border-l">
        <div className="flex justify-center">
          <FontAwesomeIcon icon={faTrash} onClick={onRemoveRow} className="pr-2 text-gray-700 cursor-pointer" />
        </div>
      </td>
    </tr>
  );
};

const ChronologyEditorTable = ({
  getHeaderGroups,
  getRowModel,
  onRowReorder,
  refetchChronology,
  hasReorderError,
  handleRemoveRow,
  clearAllFilters,
  hasActiveFilters,
  isLoadingChronologyTable,
}: ChronologyEditorTableProps) => {
  const headersLength = getHeaderGroups()[0].headers.length - hiddenColumns.length;

  return (
    <div className="w-full h-[calc(100vh-350px)]">
      <table className="w-full">
        {isLoadingChronologyTable && <StageSpinner className="m-auto" size={25} color={'#4161FF'} />}
        <thead className="h-12" style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)' }}>
          {getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              <th style={{ width: '50px' }} />
              {headerGroup.headers.map((header) => {
                const cellStyle = hiddenColumns.includes(header.id) ? { display: 'none' } : {};
                const filterValue = header.column.getFilterValue() as FilterOption[];
                return (
                  <th
                    key={header.id}
                    className="not-italic font-medium"
                    style={{
                      ...cellStyle,
                      color: 'var(--colors-primary-slate-400, #8897AE)',
                      maxWidth: header.getSize(),
                    }}
                  >
                    <div className={`flex flex-row items-center justify-start pl-3`}>
                      {header.id === 'fact_date'
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanFilter() && header.column.id === 'verified' && (
                        <MultiselectFilter column={header.column} filterValue={filterValue} />
                      )}
                    </div>
                  </th>
                );
              })}
              <th style={{ width: '50px' }} />
            </tr>
          ))}
        </thead>
        <tbody>
          {hasReorderError ? (
            <tr className="">
              <td colSpan={headersLength}>
                <div className="flex flex-col items-center pt-7">
                  <p className="mb-5">There was an error reordering the table</p>
                  <button
                    className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold  w-56 cursor-pointer"
                    style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                    onClick={refetchChronology}
                  >
                    Refetch chronology
                  </button>
                </div>
              </td>
            </tr>
          ) : (
            getRowModel().rows.map((row, i) => {
              return (
                <DraggableRow row={row} onRowReorder={onRowReorder} key={row.id} handleRemoveRow={handleRemoveRow} />
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ChronologyEditorTable;
