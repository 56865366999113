import Background from 'assets/bg-explorer.png';
import ChronologyView from 'assets/chronologies.png';
import FactsView from 'assets/facts.png';
import NewCase from 'assets/new-case.png';

const Guide = () => {
  return (
    <div className="font-sans flex flex-col items-start justify-start text-black  h-screen overflow-auto">
      <div
        className="w-full h-[35vh] flex items-center"
        style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover' }}
      >
        <h1 className="text-5xl not-italic font-semibold px-20 py-10">Wexler: Chronos User Guide</h1>
      </div>
      <div className="grid grid-cols-2 grid-rows-[auto,auto,auto] gap-4 w-full">
        <div className="col-span-2 px-5 overview-div mb-5">
          <h2 className="text-2xl font-semibold mb-0.5 py-5" style={{ color: 'rgba(28, 28, 28, 0.60)' }}>
            Overview
          </h2>
          <ul className="list-disc pl-5 ">
            <li className="py-2">
              <span className="font-bold">Wexler: Chronos</span> helps solicitors in private practice and in house to
              create factually accurate and comprehensive chronologies efficiently and effectively, using generative AI.
            </li>
            <li className="py-2">
              <span className="font-bold">
                The problem we solve is the difficulty of establishing facts in a dispute or investigation. We present
                the facts and their dates (the ‘story’ of the case), in a chronology, analyse why these facts are
                relevant to the dispute and deduplicate facts where they are repeated across documents.
              </span>
            </li>
            <li className="py-2">
              It is designed to save time reviewing documents, ensure that no detail gets missed and help to establish
              the facts in any dispute or investigation.
            </li>
            <li className="py-2">
              The product is designed as a workflow tool in which you can extract and review facts, while our algorithm
              suggests whether they are relevant to the dispute based on a synopsis the user provides.
            </li>
            <li className="py-2">
              The output is a source of facts for your case which saves written off time, improves recovery rates and
              case outcomes, and enhances team collaboration.
            </li>
            <li className="py-2">
              It can be shared internally, with clients, or with outside counsel, and it lives and grows alongside the
              case.
            </li>
            <li className="py-2">
              The final output is broadly accurate, but as with any AI system, must be verified by a user before
              finalising, through our verification flow.
            </li>
            <li className="py-2">
              We recommend that Wexler be used for ‘hot’ or ‘key’ documents rather than triaging every document in the
              case, due to the costs involved as well as how the product works best.
            </li>
            <li className="py-2 font-bold">
              We work closely with our users to develop the product based on their needs. We can respond quickly to bugs
              or issues, should they arise and we are always on hand to help. If you have feedback or queries, please
              contact us directly at{' '}
              <a className="text-blue-500 font-bold" href="mailto:gregory@wexler.ai">
                gregory@wexler.ai
              </a>
              .
            </li>
          </ul>
        </div>

        {/* Uploading & Overview Screen */}
        {/* Cell 1 */}
        <div className="px-5 uploading-div">
          <h2 className="text-2xl font-semibold mb-0.5 py-5" style={{ color: 'rgba(28, 28, 28, 0.60)' }}>
            Uploading & Overview Screen
          </h2>
          <ul className="list-disc pl-5 ">
            <li className="py-2">
              <span className="font-bold">File Upload:</span> Supports EML, MHT, DocX, Doc, PPTx, PDF, and Spreadsheet
              files, as well as MSG files and more.
            </li>
            <li className="py-2">
              A cap of{' '}
              <span className="font-bold">
                100,000 pages currently exists per upload. However, since our pricing model is calculated on a per page
                basis, we recommend fewer pages submitted per upload to get the most out of the platform
              </span>
            </li>
            <li className="py-2">
              <span className="font-bold">Integrations:</span> Integrations with document management systems and
              ediscovery/disclosure platforms are in development, but not yet live
            </li>
            <li className="py-2">
              <span className="font-bold">Case Synopsis:</span> So that the algorithm can learn the legal issues,
              parties, and case objectives, it’s essential for the user to give a synopsis of the central issues in the
              case at the beginning of the workflow. Please read the document here to understand how to draft a
              synopsis.
            </li>
            <li className="py-2">
              <span className="font-bold">Document Processing Times: </span>
              Wexler provides a conservative estimate for the time it takes to ‘chronologise’.{' '}
              <span className="font-bold">You will receive an email when it has finished</span>, but you can expect
              anywhere from <span className="font-bold">1-8</span> hours depending on the volume and length of
              documents.
            </li>
            <li className="py-2">
              <span className="font-bold">Queue System:</span> When several users are uploading at once, your documents
              go into a queue system. You will be notified when your case is out of the queue and processing.
            </li>
            <li className="py-2">
              <span className="font-bold">Please Do Not Close This Tab:</span> You{' '}
              <span className="font-bold">can</span> close the tab and it will not affect the upload; we have added this
              text to ensure you can navigate back to the page easily.
            </li>
            <li className="py-2">
              <span className="font-bold">Adding to Existing Chronologies:</span> Users can add more documents to
              existing chronologies, so that Wexler is used routinely to build the narrative of the case as more
              documents become disclosed, from day 1 of a matter right through to trial.
            </li>
          </ul>
        </div>
        {/* Cell 2 */}
        <div className="flex flex-col py-7 pr-5 ">
          <div className="image-1">
            <img src={NewCase} alt="Description of Uploading & Overview Screen" />
          </div>
        </div>
        {/* Cell 3 */}
        {/* Facts Analysis & Facts Screen */}
        <div className="px-5 facts-div">
          <h2 className="text-2xl font-semibold mb-2 py-5" style={{ color: 'rgba(28, 28, 28, 0.60)' }}>
            Facts Analysis & Facts Screen
          </h2>
          <ul className="list-disc pl-5 mb-4">
            <li className="py-2">
              <span className="font-bold">Fact Extraction:</span> Wexler's algorithm extracts all facts included within the submitted documents, establishing what is a ‘primary fact’, a ‘secondary fact’ and a ‘litigation fact’. The product is deliberately conservative, and extracts every fact, so that users can filter down by different keywords for different elements of a given case to conduct their factual review.
            </li>
            <li className="py-2">
              <span className="font-bold">Fact Categorisation:</span>
              <ul className="list-disc pl-5">
                <li className="py-2">
                  <span className="font-bold">
                  Wexler determines relevance to the case of the facts and why they are relevant.
                  </span>
                </li>
                <li className="py-2">
                  <span className="font-bold">Relevance:</span>
                  <ul style={{ listStyleType: 'circle' }} className="pl-5">
                    <li className="py-2">Green 'Yes': Highly relevant facts.</li>
                    <li className="py-2">Red 'No': Clearly irrelevant facts.</li>
                    <li className="py-2">
                      Amber 'May be relevant': Requires review from the user to establish the relevance.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="py-2">
              <span className="font-bold">Significance:</span> This shows <span className="font-bold">why</span> a fact has been deemed to be relevant, based on the synopsis and the rest of the document is has been taken from. This helps to piece together the story of the case.
            </li>
            <li className="py-2">
              <span className="font-bold">Auto-selection: </span> The product will automatically select facts with dates that are relevant for inclusion in the final chronology. Users can deselect these should they feel that they are not relevant, or add more different facts to include in their chronology.
            </li>
            <li className="py-2">
              <span className="font-bold">Filtering:</span> Use keywords or time frames to filter facts on the 'Facts'
              page.
            </li>
            <li className="py-2">
              <span className="font-bold">Selection:</span> Toggle or select the facts to create{' '}
              <span className="font-bold">different chronologies</span> about a case from the documents you have
              provided.
              <ul style={{ listStyleType: 'circle' }} className="pl-5">
                <li className="py-2">You can create chronologies based on a defined date or filtered keyword. </li>
              </ul>
            </li>
            <li className="py-2">
              <span className="font-bold">Search:</span>
              <ul className="list-disc pl-5" style={{ listStyleType: 'circle' }}>
                <li className="py-2">
                  <span className="font-bold">Wexler: </span>uses ‘Hybrid’ Search, which covers both keyword and
                  ‘semantic’ searching. This means that not only will keywords return results but also semantically
                  similar phrases.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {/* Image 2 */}
        {/* Cell 4 */}
        <div className="flex flex-col py-7 pr-5 mb-5">
          <div className="image-2">
            <img src={FactsView} alt="Description of Facts Analysis & Facts Screen" />
          </div>
        </div>
        {/* Cell 5 */}
        {/* Chronologies */}
        <div className="px-5 justify-center chronologies-div">
          <h2 className="text-2xl font-semibold mb-2 py-5" style={{ color: 'rgba(28, 28, 28, 0.60)' }}>
            Chronology Creation
          </h2>
          <ul className="list-disc pl-5 mb-4">
            <li className="py-2">
              <span className="font-bold">Chronology Creation:</span> Create the final chronology with the selected
              facts in the facts screen.
            </li>
            <li className="py-2">
              <span className="font-bold">Chronology List:</span> A list of all of your chronologies from the document
              set will appear, with different outputs based on what you select.
            </li>
            <li className="py-2">
              <span className="font-bold">Editing:</span> Chronologies are editable, and new entries can be manually
              included.
            </li>
            <li className="py-2">
              <span className="font-bold">Verification: </span> Verification is a crucial step, before finalising the output. Wexler will 
              show you who has verified the facts.
            </li>
            <li className="py-2">
              <span className="font-bold">Export & Collaboration:</span>
              <ul style={{ listStyleType: 'disc' }} className="pl-5">
                <li className="py-2">Download in Excel format.</li>
                <li className="py-2">Collaborate directly within Wexler by sharing the URL other users.</li>
              </ul>
            </li>
          </ul>
          {/* </div> */}
        </div>
        {/* Cell 6 */}
        {/* Image 3 */}
        <div className="flex flex-col py-7 pr-5 mb-5">
          <div className="image-3">
            <img src={ChronologyView} alt="Description of Chronologies" />
          </div>
        </div>
      </div>
      <div className="mb-5 ml-5">
        If you have any further questions or issues, please email{' '}
        <span className="font-bold text-blue-500">gregory@wexler.ai</span> directly.
      </div>
    </div>
  );
};

export default Guide;
