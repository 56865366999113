import { useEffect, useState } from 'react';

import { ColumnSizingState, VisibilityState } from '@tanstack/react-table';

import {
  LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING,
  LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY,
  initialVisibilityState,
} from '../constants';

function useLocalStorageState<T>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(() => {
    const stored = localStorage.getItem(key);
    return stored ? JSON.parse(stored) : defaultValue;
  });

  useEffect(() => {
    const current = localStorage.getItem(key);
    const newValue = JSON.stringify(state);
    if (current !== newValue) {
      localStorage.setItem(key, newValue);
    }
  }, [key, state]);

  return [state, setState];
}

function useColumnState() {
  const [columnSizing, setColumnSizing] = useLocalStorageState<ColumnSizingState>(
    LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING,
    {},
  );
  const [columnVisibility, setColumnVisibility] = useLocalStorageState<VisibilityState>(
    LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY,
    initialVisibilityState,
  );

  return { columnSizing, setColumnSizing, columnVisibility, setColumnVisibility };
}

export default useColumnState;
