import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerPanel = styled.div`
  left: '0px';
  width: ${({ compressed }) => (compressed ? '80px !important;' : '240px !important;')};
  flex-shrink: 0;
  position: sticky;
  padding-right: 10px;
  background: #0d1d54;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: all 0.7s;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-blend-mode: normal, overlay, color-burn, normal;
  color: white;
  overflow: hidden;
`;

export const ContainerMenu = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 30px;
  padding-bottom: 10px;
`;

export const MenuOption = styled(Link)`
  font-family: 'Lota Grotesque';
  font-family: 'Lota Grotesque';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */
  color: #84828a;
`;

export const BackPanel = styled.div`
  position: fixed;
  top: 0px;
  left: ${({ open }) => (open ? '0px' : '-100vw')};
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.6s;
`;
