interface ButtonProps {
  onClick: () => void;
  text: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  type?: 'primary' | 'secondary';
}

const Button = ({ onClick, text, className, size, type }: ButtonProps) => {
  // Check if a custom className is not provided, then apply default styles based on type
  if (!className) {
    if (type === 'primary') {
      className = 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded';
    } else if (type === 'secondary') {
      className = 'bg-[#ECEFFF] text-blue-600 font-semibold py-1 px-4 rounded';
    }
  }
  if (size === 'small') {
    className += ' text-sm';
  } else if (size === 'large') {
    className += ' text-lg';
  } else if (size === 'medium') {
    className += ' text-base';
  }

  return (
    <button className={className} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
