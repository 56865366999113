import React from 'react';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ActionButtonProps {
  title: string;
  description: string;
  icon: IconDefinition;
  onClick: () => void;
  borderActive?: boolean;
}

const ActionButton = ({ title, description, icon, onClick, borderActive }: ActionButtonProps) => {
  return (
    <div className={`shimmer-border ${borderActive ? 'border-active' : ''}`} onClick={onClick}>
      <div className="bg-gray-50 hover:cursor-pointer w-full rounded-full px-4 py-2 flex items-center gap-2 border">
        <FontAwesomeIcon icon={icon} className="text-gray-800 w-4 h-4 cursor-pointer mr-2 border p-2 rounded-full" />
        <div>
          <p>{title}</p>
          <p className="text-xs text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ActionButton;
