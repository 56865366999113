import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import CASES_WITH_ACCESS_TO_SUMMARY from 'constants/summaryPageAllowedCases';
import { useQuery } from 'react-query';

interface ChronosFactResponse {
  event_ids: string[];
}

function useKeyFacts(caseId: string | null | undefined) {
  const { fetchConfigGET } = useGetFetchConfig();

  // Disabled if caseId is e2fca5e7-9079-49a6-84a9-d9e5d56fe71e
  const enabled = caseId && CASES_WITH_ACCESS_TO_SUMMARY.has(caseId) ? true : false;

  const { data, error, isLoading, isFetching, refetch } = useQuery<ChronosFactResponse, Error>(
    ['keyFactIds', caseId],
    async () => {
      const response = await fetch(`${APIBaseChronos}/client/case/fact/keyFacts/${caseId}`, fetchConfigGET);
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { enabled: enabled, cacheTime: 0 },
  );
  return { data, error, isLoading, isFetching, refetch };
}

export default useKeyFacts;
