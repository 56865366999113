import styled from 'styled-components';

export const ToggleContainer = styled.button`
  .react-toggle--checked .react-toggle-track {
    background-color: #4161ff !important;
  }
  .react-toggle-track {
    background-color: #bbc4cd !important;
  }
  .react-toggle-thumb {
    border: none;
  }
`;
