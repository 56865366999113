import { SortingState } from '@tanstack/react-table';

export const LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING = 'fact-table-column-sizing';
export const LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY = 'fact-table-column-visibility';
export const initialSortingState: SortingState = [
  {
    id: 'date_of_subject',
    desc: false,
  },
];

export const initialVisibilityState = {
  source_text: false,
  date_uploaded: false,
  subject_matter: false,
  document_date_text: false,
  event_id: false,
};
