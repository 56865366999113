import { RedirectToLogin, RequiredAuthProvider } from '@propelauth/react';
import Sidebar from 'components/organisms/Sidebar';
import { UserProvider } from 'Contexts/User';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { StageSpinner } from 'react-spinners-kit';

import { APIBase } from './api/auth';
import PrivateApp from './PrivateApp';

// import { HotjarProvider } from './Contexts/Hotjar';
import 'intro.js/introjs.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-tooltip/dist/react-tooltip.css';

const queryClient = new QueryClient();

const BrowserPrompt = () => {
  return (
    <div className="flex lg:hidden justify-center items-center h-screen">
      <p>Wexler is optimised for desktop screens</p>
    </div>
  );
};

function App() {
  return (
    <RequiredAuthProvider
      authUrl={APIBase}
      displayWhileLoading={
        <div className="h-screen w-screen flex items-center justify-center">
          <StageSpinner className="m-auto" color={'#081D57'} />
        </div>
      }
      displayIfLoggedOut={<RedirectToLogin />}
    >
      <UserProvider>
        {/* <HotjarProvider> */}
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <BrowserPrompt />
            <div className="flex flex-row h-screen">
              <Sidebar />
              <Routes>
                <Route path="app/*" element={<PrivateApp />} />
                <Route path="*" element={<Navigate to="/app" />} />
              </Routes>
            </div>
          </BrowserRouter>
        </QueryClientProvider>
        {/* </HotjarProvider> */}
      </UserProvider>
    </RequiredAuthProvider>
  );
}

export default App;
