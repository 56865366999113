import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { faArrowLeft, faChevronDown, faChevronUp, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import { PIPELINE_FREEZE_LOAD_STATUS, PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from 'constants/pipelineStatus';
import 'moment-timezone';
import CASES_WITH_ACCESS_TO_SUMMARY from 'constants/summaryPageAllowedCases';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosRun } from 'types';

import CaseSummary from './CaseSummary';
import ChronologyEditor from './ChronologyEditor';
import NavigationTabs from './components/editor/NavigationTabs';
import StatusDisplay from './components/editor/StatusDisplay';
import DocumentsEditor from './DocumentsEditor';
import DocViewer from './DocViewer';
import FactsEditor from './FactsEditor';
import computeCaseMessage from './helpers/computeCaseMessage';
import RunsView from './RunsView';
import { customStylesModal } from './styles';
import useGetFetchConfig from '../../../api/useGetFetchConfig';

// const caseState.runs = [
//   {
//     run_id: '7eade29c-10ee-44f1-b69b-05b67150d256',
//     case_id: '2d6c35ca-2fde-4262-9f54-16851a67abe2',
//     is_update: true,
//     created_by: '3e65e444-4680-4c1f-ad35-19949af37345',
//     date_requested: new Date(Date.now()),
//     pipeline_status: 'complete',
//     time_estimate_datetime: new Date(Date.now()),
//     in_queue: false,
//     queue_position: null,
//     queue_estimate_datetime: null,
//   },
// ];

const CaseEditor: React.FC = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  // Feature flag hide/show summary page based on case_id
  const isSummaryPageVisible = useMemo(() => {
    return caseId && CASES_WITH_ACCESS_TO_SUMMARY.has(caseId);
  }, [caseId]);

  const docId = searchParams.get('docId');
  const eventId = searchParams.get('eventId');
  const pageNumber = Number(searchParams.get('pageNumber'));
  const location = useLocation();
  const [caseState, setCaseState] = useState({
    caseName: '',
    runs: [],
    casePipelineStatus: '',
    queueEstimateDate: new Date(),
    inQueue: false,
    queuePosition: null,
    matterId: '',
    estimateDate: new Date(),
    modalIsOpen: false,
    runStatusIsOpen: false,
  });
  const navigate = useNavigate();
  const { fetchConfigGET } = useGetFetchConfig();

  const pageRef = useRef<HTMLDivElement>(null);

  const { isFetching: isLoadingCase, refetch: refetchCase } = useQuery(
    'currentCase',
    () => fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET).then((res) => res.json()),
    {
      cacheTime: 0,
      enabled: !PIPELINE_FREEZE_LOAD_STATUS.includes(caseState.casePipelineStatus),
      refetchInterval: 3000,
      onSuccess: (response) => {
        if (response?.type === 'unauthorized') {
          Swal.fire({
            title: '',
            text: 'Unauthorized. You are being redirected to the matters explorer',
            showConfirmButton: false,
            timer: 3000,
          });
          navigate(`/app/chronos/matters`);
        } else if (response?.caseObject?.case_id) {
          setCaseState((prevState) => ({
            ...prevState,
            caseName: response.caseObject.case_name,
            runs: response.runs,
            casePipelineStatus: response.runs[0].pipeline_status,
            queueEstimateDate: response.runs[0].queue_estimate_datetime,
            inQueue: response.runs[0].in_queue,
            queuePosition: response.runs[0].queue_position || null,
            estimateDate: response.runs[0].time_estimate_datetime,
            matterId: response.caseObject.matter_id,
          }));
        }
      },
    },
  );

  useEffect(() => {
    refetchCase();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (docId) {
      setCaseState((prev) => ({ ...prev, modalIsOpen: true }));
    }
  }, [docId, eventId]);

  const chronologyId = searchParams.get('chronologyId');

  const closeModal = useCallback(() => {
    if (chronologyId) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('caseId', caseId || '');
      searchParams.delete('docId');
      navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
    } else {
      setCaseState((prev) => ({ ...prev, modalIsOpen: false }));
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('caseId', caseId || '');
      searchParams.delete('eventId');
      searchParams.delete('docId');
      searchParams.delete('pageNumber');
      navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
    }
    // eslint-disable-next-line
  }, [navigate, chronologyId]);

  const handleClickBack = () => {
    navigate(`/app/chronos/explore?matterId=${caseState.matterId}`);
  };

  const message = useMemo(
    () =>
      computeCaseMessage(
        caseState.inQueue,
        caseState.queuePosition,
        caseState.estimateDate,
        caseState.queueEstimateDate,
      ),
    [caseState.inQueue, caseState.queuePosition, caseState.estimateDate, caseState.queueEstimateDate],
  );

  const handleClickStatusView = () => {
    setCaseState((prev) => ({ ...prev, runStatusIsOpen: !prev.runStatusIsOpen }));
  };

  const runsInProgress = caseState.runs.filter((run: ChronosRun) => {
    return ![PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(run.pipeline_status);
  });

  return (
    <>
      {docId && (
        <Modal
          isOpen={caseState.modalIsOpen}
          onRequestClose={closeModal}
          style={customStylesModal}
          contentLabel="Document viewer"
        >
          <DocViewer docId={docId} caseId={caseId} eventId={eventId} pageNumber={pageNumber} />
        </Modal>
      )}
      <div ref={pageRef} className="flex flex-col items-start justify-start w-full h-full text-black overflow-y-scroll">
        <div className="w-full h-full pt-8 bg-center pb-2 pr-8 pl-8">
          {PIPELINE_PROCESSING_STATUS.includes(caseState.casePipelineStatus) ||
          caseState.casePipelineStatus === PIPELINE_STATUS.failed.value ? (
            <>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer"
                onClick={handleClickBack}
              />
              <StatusDisplay casePipelineStatus={caseState.casePipelineStatus} message={message} />
            </>
          ) : isLoadingCase ? (
            <div className="w-full flex flex-col items-center justify-center">
              <StageSpinner className="m-auto" size={25} color={'#4161FF'} /> Your case is being loaded
            </div>
          ) : [PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(caseState.casePipelineStatus) ? (
            <div className="w-full h-full">
              <div className="flex items-center flex-col">
                <div className="w-full flex flex-row justify-between">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="mr-3 text-black w-6 h-6 cursor-pointer"
                    onClick={() => handleClickBack()}
                  />
                  {
                    <div
                      className="relative px-5 rounded-xl  py-2 bg-white border border-solid items-center justify-center text-black flex flex-row cursor-pointer"
                      style={{ borderColor: `${!!runsInProgress.length ? '#E3B336' : '#39E336'}` }}
                      onClick={handleClickStatusView}
                    >
                      <FontAwesomeIcon
                        icon={faClock}
                        style={{ color: `${!!runsInProgress.length ? '#E3B336' : '#39E336'}` }}
                      />
                      <div
                        className="mx-4 font-semibold"
                        style={{ color: `${!!runsInProgress.length ? '#E3B336' : '#39E336'}` }}
                      >
                        {!!runsInProgress.length
                          ? `${runsInProgress.length} ${runsInProgress.length === 1 ? 'run' : 'runs'} in progress`
                          : 'All runs completed'}
                      </div>
                      <FontAwesomeIcon
                        icon={caseState.runStatusIsOpen ? faChevronUp : faChevronDown}
                        style={{ color: `${!!runsInProgress.length ? '#E3B336' : '#39E336'}` }}
                      />
                      {caseState.runStatusIsOpen && (
                        <div className="absolute -left-80 top-8 z-10" style={{ left: '-315px' }}>
                          <RunsView runs={caseState.runs} isLoadingRuns={false} />
                        </div>
                      )}
                    </div>
                  }
                </div>
                <p
                  className="w-full focus:outline-none text-black text-2xl not-italic font-bold placeholder:text-gray-400 rounded-lg mt-2"
                  style={{}}
                >
                  {caseState.caseName}
                </p>
              </div>
              {caseId && <NavigationTabs caseId={caseId} />}
              <Routes>
                {isSummaryPageVisible && <Route path="summary" element={<CaseSummary outerRef={pageRef} />} />}
                <Route path="documents" element={<DocumentsEditor />} />
                <Route path="facts" element={<FactsEditor />} />
                <Route path="chronology" element={<ChronologyEditor />} />
                {isSummaryPageVisible ? (
                  <Route path="*" element={<Navigate to={`/app/chronos/case-editor/summary`} />} />
                ) : (
                  <Route path="*" element={<Navigate to={`/app/chronos/case-editor/documents`} />} />
                )}
              </Routes>
            </div>
          ) : (
            <div className="w-full flex flex-col items-center justify-center">
              <StageSpinner className="m-auto" size={25} color={'#4161FF'} /> Your case is being loaded
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CaseEditor;
