import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { ChronosFact } from 'types';

interface ChronosFactResponse {
  facts: ChronosFact[];
  totalFacts: number;
  allFacts: ChronosFact[];
  appliedFilters: [string, string][];
  appliedSortings: [string, string][];
  page: number;
  pageSize: number;
  totalFactsCount: number;
  includedFactsCount: number;
}

function useFactsData(
  docId: string | null | undefined,
  caseId: string | null | undefined,
  searchQuery: string | null | undefined,
  page: number,
  pageSize: number,
  sorting: SortingState,
  columnFilters: ColumnFiltersState,
) {
  const { fetchConfigGET } = useGetFetchConfig();

  const { data, error, isLoading, isFetching, refetch } = useQuery<ChronosFactResponse, Error>(
    ['userFacts', docId, caseId, searchQuery, page, pageSize, sorting, columnFilters],
    async () => {
      const encodedSearchQuery = searchQuery ? encodeURIComponent(searchQuery) : '';

      const sortParams = sorting.map((sort) => `sort_${sort.id}=${sort.desc ? 'desc' : 'asc'}`).join('&');

      const filterParams = columnFilters
        .map((filter) => {
          if (Array.isArray(filter.value)) {
            return `filter_${filter.id}=${filter.value.map((val) => val.value).join(',')}`;
          }
          return `filter_${filter.id}=${filter.value}`;
        })
        .join('&');

      const response = await fetch(
        `${APIBaseChronos}/client/case/fact/${caseId}?docId=${docId}&searchQuery=${encodedSearchQuery}&page=${page}&pageSize=${pageSize}&${sortParams}&${filterParams}`,
        fetchConfigGET,
      );
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { enabled: false, cacheTime: 0 },
  );

  return { data, error, isLoading, isFetching, refetch };
}

export default useFactsData;
