import { useEffect, useRef, useState } from 'react';

import { faMessage } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { TooltipStylesComments } from 'constants/styles';
import moment from 'moment';
import { Mention, MentionsInput } from 'react-mentions';
import { useMutation, useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { CheckState } from 'types';

export type SetCheckedRows = React.Dispatch<React.SetStateAction<Record<string, CheckState>>>;
interface TextInputCellProps {
  thread_id: string;
  comment: string;
  comments_count: number;
  setCheckedRows: SetCheckedRows;
  currentUserId: string;
}

interface Comment {
  comment_by_email: string;
  comment_by: string;
  comment_date: Date;
  comment: string;
  comment_id: string;
}

interface CommentsResponse {
  comments: Comment[];
}
interface User {
  id: string;
  display: string;
}

interface UserResponse {
  users: Array<{
    userId: string;
    email: string;
  }>;
}

const CommentCell = ({ thread_id, comment, comments_count, currentUserId }: TextInputCellProps) => {
  // State
  const [value, setValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [commentCount, setCommentCount] = useState<number>(comments_count);
  const [loadingNewComment, setLoadingNewComment] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);

  const tooltipRef = useRef(null);

  // Data fetching
  const { getFetchConfig, fetchConfigGET } = useGetFetchConfig();
  const { data: userData } = useQuery<User[], Error>(
    'org-users',
    () =>
      fetch(`${APIBaseChronos}/client/org/usersByEvent/${thread_id}`, fetchConfigGET).then((res) => {
        if (!res.ok) throw new Error('Network response was not ok');
        return res.json().then((data: UserResponse) =>
          data.users.map((user) => ({
            id: user.userId,
            display: user.email,
          })),
        );
      }),
    {
      enabled: isOpen, // Enable user query only when the tooltip is open
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: comments,
    isLoading,
    isError,
    refetch,
  } = useQuery<CommentsResponse, Error>(
    ['comments', thread_id], // Include thread_id in the query key to ensure unique caching per thread
    () =>
      fetch(`${APIBaseChronos}/client/case/fact/comment/${thread_id}`, fetchConfigGET).then((res) => {
        if (!res.ok) throw new Error('Network response was not ok');
        return res.json();
      }),
    {
      refetchOnWindowFocus: false,
      enabled: isOpen, // This line controls the execution of the query based on the isOpen state.
    },
  );

  const { mutate } = useMutation(
    (newComment: { comment: string }) => {
      setLoadingNewComment(true);
      Swal.fire({
        title: '',
        text: 'Adding comment',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 3000,
      });
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          comment: newComment.comment,
          url: window.location.href,
        },
      });
      setLoadingNewComment(false);
      return fetch(`${APIBaseChronos}/client/case/fact/comment/${thread_id}`, fetchConfig).then((res) => res.json());
    },
    {
      onSuccess: (data) => {
        refetch();
        setValue('');
        Swal.fire({
          title: '',
          text: 'Comment added successfully',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
        setCommentCount(commentCount + 1);
      },
      onError: (error) => {
        console.error('Error posting comment:', error);
        Swal.fire({
          title: '',
          text: 'Failed to post comment. Please try again.',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      },
    },
  );

  const { mutate: editComment } = useMutation(
    (updatedComment: { comment: string }) => {
      Swal.fire({
        title: '',
        text: 'Updating comment...',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 3000,
      });
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          comment: updatedComment.comment,
        },
      });
      return fetch(`${APIBaseChronos}/client/case/fact/comment/${editingCommentId}`, fetchConfig).then((res) =>
        res.json(),
      );
    },
    {
      onSuccess: () => {
        refetch();
        setValue('');
        setEditingCommentId(null);
        Swal.fire({
          title: '',
          text: 'Comment updated successfully',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      },
      onError: (error) => {
        console.error('Error updating comment:', error);
        Swal.fire({
          title: '',
          text: 'Failed to update comment. Please try again.',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      },
    },
  );

  const { mutate: deleteComment } = useMutation(
    (commentId: string) => {
      Swal.fire({
        title: '',
        text: 'Deleting comment...',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 3000,
      });
      const fetchConfig = getFetchConfig({
        method: 'DELETE',
      });
      return fetch(`${APIBaseChronos}/client/case/fact/comment/${commentId}`, fetchConfig).then((res) => res.json());
    },
    {
      onSuccess: () => {
        refetch();
        Swal.fire({
          title: '',
          text: 'Comment deleted successfully',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
        setCommentCount(commentCount - 1);
      },
      onError: (error) => {
        console.error('Error deleting comment:', error);
        Swal.fire({
          title: '',
          text: 'Failed to delete comment. Please try again.',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
      },
    },
  );

  // Handlers
  const handleEditComment = (comment: Comment) => {
    if (comment.comment_by !== currentUserId) return; // Ensure only the user who created the comment can edit it
    setValue(comment.comment);
    setEditingCommentId(comment.comment_id);
    setIsOpen(true);
  };

  const handleSubmitCommentUpdate = () => {
    editComment({ comment: value });
  };

  const handleCancelUpdate = () => {
    setValue('');
    setEditingCommentId('');
  };

  const handleDeleteComment = (comment: Comment) => {
    if (comment.comment_by !== currentUserId) return; // Ensure only the user who created the comment can delete it
    deleteComment(comment.comment_id);
  };

  const handleSubmitComment = () => {
    if (!value) return;
    mutate({ comment: value });
  };

  const renderHighlightedText = (text: string) => {
    // Regex to match @[****](XXXXX)
    const regex = /@\[(.*?)\]\(.*?\)/g;
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // This means it's the matched group which is ****
        return (
          <span key={index} style={{ backgroundColor: 'yellow' }}>
            {part}
          </span>
        );
      }
      return part; // Normal text not inside @[****]
    });
  };

  // Effects
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Ensure that the event target is an Element before proceeding with contains check
      // @ts-ignore
      if (tooltipRef.current && (event.target as Node) && !tooltipRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // Add when the tooltip is open and remove when closed
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setCommentCount(comments_count);
  }, [comments_count]);

  return (
    <div className="flex flex-row items-center justify-start mr-5">
      <div className="w-[calc(100%-60px)]">{!!comment ? renderHighlightedText(comment) : ''}</div>
      {!commentCount ? (
        <div
          className="relative h-full mx-5 cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
          data-tooltip-id={`comment-thread-${thread_id}`}
        >
          <FontAwesomeIcon
            /* @ts-ignore */
            icon={faMessage}
            className=" text-black w-7 h-7 absolute -top-3"
            style={{ color: '#8897AE' }}
          />
          <FontAwesomeIcon
            icon={faPlus}
            className=" text-black w-3 left-2 h-3 absolute"
            style={{ color: '#8897AE', top: '-7px' }}
          />
        </div>
      ) : (
        <div
          className="relative h-full mx-5 cursor-pointer"
          data-tooltip-id={`comment-thread-${thread_id}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FontAwesomeIcon
            /* @ts-ignore */
            icon={faMessage}
            className=" text-black w-7 h-7 absolute -top-3"
            style={{ color: '#8897AE' }}
          />
          <div
            className="left-3 h-5 absolute text-white px-1 flex items-center justify-center rounded-md"
            style={{ backgroundColor: '#4161FF', top: '-17px', fontSize: '10px' }}
          >
            {commentCount ? `+${commentCount}` : ''}
          </div>
        </div>
      )}
      <Tooltip
        id={`comment-thread-${thread_id}`}
        style={{ ...TooltipStylesComments }}
        place={'left'}
        isOpen={isOpen}
        opacity={1}
        clickable
      >
        <div ref={tooltipRef} className="w-full">
          {isLoading ? (
            <div>Loading comments...</div>
          ) : isError ? (
            <div>Error loading comments.</div>
          ) : (
            <div className="flex flex-col w-full items-start justify-center">
              <div className=" max-h-96 overflow-y-scroll">
                {!!comments?.comments?.length &&
                  [...comments?.comments].reverse()?.map((comment) => {
                    return (
                      <div className="flex flex-col mb-2">
                        <div className="text-sm font-semibold">{comment.comment_by_email}</div>
                        <div className="text-xs">{`${moment(comment.comment_date || Date.now()).format(
                          'MMMM DD, YYYY',
                        )}`}</div>
                        {editingCommentId !== comment.comment_id ? (
                          <div>{renderHighlightedText(comment.comment)}</div>
                        ) : (
                          <MentionsInput
                            value={value}
                            onChange={(e: any) => setValue(e.target.value)}
                            style={{
                              width: '100%',
                              height: '45px',
                              borderRadius: '16px',
                              border: '1px solid var(--black-10, #E7E7E7)',
                              padding: '2px 8px',
                              outline: 'none',
                            }}
                            placeholder={"Mention people using '@'"}
                            a11ySuggestionsListLabel={'Suggested mentions'}
                          >
                            <Mention
                              data={userData || []}
                              trigger="@"
                              style={{
                                backgroundColor: '#EEEEEE',
                              }}
                            />
                          </MentionsInput>
                        )}
                        {comment.comment_by === currentUserId && (
                          <div className="flex flex-row">
                            {!editingCommentId && (
                              <>
                                <button className="text-blue-500 mr-2" onClick={() => handleEditComment(comment)}>
                                  Edit
                                </button>
                                <button className="text-red-500" onClick={() => handleDeleteComment(comment)}>
                                  Delete
                                </button>
                              </>
                            )}
                            {editingCommentId === comment.comment_id && (
                              <>
                                <button className="text-blue-500 mr-2" onClick={handleSubmitCommentUpdate}>
                                  Update
                                </button>
                                <button className="text-red-500" onClick={handleCancelUpdate}>
                                  Cancel
                                </button>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              {!editingCommentId && (
                <>
                  <MentionsInput
                    value={value}
                    onChange={(e: any) => setValue(e.target.value)}
                    style={{
                      width: '100%',
                      height: '45px',
                      borderRadius: '16px',
                      border: '1px solid var(--black-10, #E7E7E7)',
                      padding: '2px 8px',
                      outline: 'none',
                    }}
                    placeholder={"Mention people using '@'"}
                    a11ySuggestionsListLabel={'Suggested mentions'}
                  >
                    <Mention
                      data={userData || []}
                      trigger="@"
                      style={{
                        backgroundColor: '#EEEEEE',
                      }}
                    />
                  </MentionsInput>
                  <div className="flex flex-row">
                    <button
                      className="flex justify-center items-center px-2 py-1 mt-3 rounded-lg not-italic font-bold cursor-pointer disabled:cursor-not-allowed"
                      style={{
                        color: '#ECEFFF',
                        backgroundColor: '#4161FF',
                      }}
                      onClick={handleSubmitComment}
                    >
                      {loadingNewComment ? <StageSpinner className="m-auto" size={25} color={'#4161FF'} /> : 'Comment'}
                    </button>
                    <button
                      className="flex justify-center items-center px-2 py-1 mt-3 ml-5 rounded-lg not-italic font-bold cursor-pointer disabled:cursor-not-allowed"
                      style={{
                        backgroundColor: '#FFFFFF',
                        color: '#4161FF',
                        border: '1px solid #4161FF',
                      }}
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default CommentCell;
