import { memo, useEffect, useState } from 'react';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StageSpinner } from 'react-spinners-kit';

export interface PaginationProps {
  currentPage: number;
  noOfPages: number;
  canGetPrevPage: boolean;
  canGetNextPage: boolean;
  prevPage: () => void;
  nextPage: () => void;
  isLoadingFacts: boolean;
  goToPage?: (value: number) => void;
}

const Pagination = ({
  currentPage,
  noOfPages,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  isLoadingFacts,
  goToPage,
}: PaginationProps) => {
  const prevButtonDisabled = isLoadingFacts || !canGetPrevPage;
  const nextButtonDisabled = isLoadingFacts || !canGetNextPage;
  const [currentPageString, setCurrentPageString] = useState('1');

  const handleChangePage = (value: string) => {
    setCurrentPageString(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const pageNumber = parseInt(currentPageString) - 1;
      goToPage && goToPage(pageNumber);
    }
  };

  useEffect(() => {
    if (currentPage !== parseInt(currentPageString) - 1) {
      setCurrentPageString((currentPage + 1).toString());
    }
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <div className="flex items-center justify-between">
      <button
        style={{ backgroundColor: '#ECEFFF' }}
        className={`px-1 py-1 rounded mr-3 cursor-pointer disabled:cursor-not-allowed hover:opacity-80`}
        onClick={prevPage}
        disabled={prevButtonDisabled}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="text-gray-700 mx-2"
          style={{ color: !prevButtonDisabled ? '#4161FF' : 'var(--colors-primary-slate-400, #8897AE)' }}
        />
      </button>
      {isLoadingFacts ? (
        <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
      ) : (
        <p className="text-center flex flex-row items-center justify-center ">
          Page{' '}
          <input
            value={currentPageString}
            className="mx-1 px-1 h-8 w-14"
            onChange={(e) => handleChangePage(e.target.value)}
            onKeyDown={handleKeyDown}
            inputMode="numeric"
            min="1"
            style={{
              borderRadius: '5px',
              border: '1px solid var(--black-10, #E7E7E7)',
            }}
          />
          of {noOfPages}
        </p>
      )}
      <button
        style={{ backgroundColor: '#ECEFFF' }}
        className={`px-1 py-1 rounded ml-3 cursor-pointer disabled:cursor-not-allowed hover:opacity-80`}
        onClick={nextPage}
        disabled={nextButtonDisabled}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className="text-gray-700 mx-2"
          style={{ color: !nextButtonDisabled ? '#4161FF' : 'var(--colors-primary-slate-400, #8897AE)' }}
        />
      </button>
    </div>
  );
};

export default memo(Pagination);
