import React from 'react';

import parse from 'html-react-parser';

const formatSummary = (summary: string, linkFunction: (id: string) => void): React.ReactNode => {
  // Regex to find any <a> tags and the parameter called eventID inside their href
  const linkRegex = /\[(\d+)~([a-f0-9-]+)~\]/g;
  const elements: React.ReactNode[] = [];

  let match: any;
  let lastIndex = 0;

  // Loop through all matches for links
  while ((match = linkRegex.exec(summary)) !== null) {
    // Push the text before the match
    if (match.index > lastIndex) {
      elements.push(parse(summary.slice(lastIndex, match.index)));
    }

    const eventID = match[2];
    // Create the JSX <a> element
    elements.push(
      <span className="text-blue-500 hover:cursor-pointer" key={match.index} onClick={() => linkFunction(eventID)}>
        [{match[1]}]
      </span>,
    );

    // Update the lastIndex to after this match
    lastIndex = linkRegex.lastIndex;
  }

  // Push any remaining text after the last link match
  if (lastIndex < summary.length) {
    summary = parse(summary.slice(lastIndex)) as string;
  }

  elements.push(summary);

  return <>{elements}</>;
};

export default formatSummary;
