import React, { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-toggle/style.css';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';

import * as styledComponents from './styles';
import { CheckState } from '../../../../../types';
import useToggleIncluded from '../../FactsEditor/hooks/useToggleIncluded';

export type SetCheckedRows = React.Dispatch<React.SetStateAction<Record<string, CheckState>>>;

interface IncludeCheckboxProps {
  included: boolean;
  eventId: string;
  caseId: string;
  editorIdEmail: string;
  callback: () => void;
}
const IncludeCheckbox = ({
  included: currentIncluded,
  eventId,
  caseId,
  editorIdEmail,
  callback,
}: IncludeCheckboxProps) => {
  const [isLoadingUpdateInclude, setLoadingUpdateInclude] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(currentIncluded);

  const { mutate: mutateUpdateIncluded } = useToggleIncluded();

  useEffect(() => {
    setChecked(currentIncluded);
  }, [currentIncluded]);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingUpdateInclude(true);
    const toggleValue = event.target.checked;
    mutateUpdateIncluded(
      { caseId, toggleValue, eventId },
      {
        onSuccess: () => {
          callback();
          setChecked(toggleValue);
        },
        onError: (error) => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to update the event',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        },
        onSettled: () => {
          setLoadingUpdateInclude(false);
        },
      },
    );
  };

  return (
    <div className="flex flex-row justify-center items-center w-auto">
      <styledComponents.ToggleContainer className="mr-3">
        <label>
          <div className="flex flex-col items-center">
            {isLoadingUpdateInclude ? (
              <FontAwesomeIcon icon={faCircleNotch} spin className="text-blue-500 w-5 h-5 mb-2" />
            ) : (
              <input
                onChange={handleToggle}
                id={`default-checkbox-${eventId}`}
                type="checkbox"
                checked={checked}
                className="cursor-pointer w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-xl focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 mb-2"
              />
            )}
            {editorIdEmail && (
              <div
                data-tooltip-id={`${eventId}-${editorIdEmail}`}
                data-tooltip-content={editorIdEmail}
                className="text-xs text-ellipsis overflow-hidden whitespace-nowrap w-32"
              >
                {`Edited by ${editorIdEmail}`}
                <Tooltip
                  id={`${eventId}-${editorIdEmail}`}
                  style={{ ...TooltipStyles, padding: '8px', width: 'max-content', borderRadius: '20px' }}
                  place="top-start"
                  opacity={1}
                />
              </div>
            )}
          </div>
        </label>
      </styledComponents.ToggleContainer>
    </div>
  );
};

export default IncludeCheckbox;
