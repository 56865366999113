const CASES_WITH_ACCESS_TO_SUMMARY = new Set([
  'e2fca5e7-9079-49a6-84a9-d9e5d56fe71e',
  '5844e4a2-b9ca-4188-b378-a406a1c0a3af',
  '94dc1511-d6da-4d89-915d-52114196cec0',
  'c1580701-0627-48c5-b1ca-96579cc3aacd',
  '01290526-cedf-4ecd-a448-52a7da8d01f2',
  '4f39b421-5d43-4b06-a045-7753186951c5',
  'd219e597-5f77-466a-a7b0-32c82ff1e8b1',
  '8ff57e08-37c2-4303-981a-092125fad509',
  'dcb72726-446a-4ef7-b5b6-8f4b6bcbb92e',
  '8cf02db1-a087-45e0-9036-f248efc88e0d',
  'a6fee726-7f1e-431b-a5d8-4cf15ff44d09',
  '7fb0a6db-b5c7-4c7b-b761-7e2be3fda570',
  'fbcb178a-0e0e-45bc-80e3-12210ce9ae3d',
]);

export default CASES_WITH_ACCESS_TO_SUMMARY;
