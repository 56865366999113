import React, { useState, useEffect, useRef } from 'react';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'components/atoms/Select';
import { SingleValue } from 'react-select';
import { FilterOption } from 'types';

interface TableFilterPopupProps {
  chosenOption: FilterOption;
  options: FilterOption[];
  onChange: (newValue: string) => void;
  placeholder?: string;
}

const TableFilterPopup: React.FC<TableFilterPopupProps> = ({ chosenOption, onChange, placeholder, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<FilterOption>(chosenOption);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);

  const handleApply = (newValue: SingleValue<FilterOption>) => {
    if (newValue?.value) {
      setSelectedOption(newValue as FilterOption);
      onChange(newValue?.value);
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      iconRef.current &&
      !iconRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="relative w-6 h-6 flex items-center justify-center">
      <div ref={iconRef}>
        <FontAwesomeIcon
          icon={faBars}
          className={`mr-2 cursor-pointer flex items-center justify-center rounded-md p-1 border shadow ${
            isOpen ? 'bg-gray-200' : ''
          }`}
          onClick={handleIconClick}
          data-testid="filter-icon"
        />
      </div>
      <div
        ref={dropdownRef}
        className={`absolute z-30 top-8 left-0 bg-white border border-gray-300 rounded shadow-xl shrink-0 w-52 transition-transform duration-300 ease-in-out ${
          isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'
        }`}
        style={{ transitionProperty: 'transform, opacity' }}
      >
        <div className="py-2 px-3">
          <Select
            selectedOption={selectedOption}
            onChange={handleApply}
            options={options}
            placeholder={placeholder ? placeholder : 'Select'}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default TableFilterPopup;
