// TODO: This is just copied from Assistant for now so that we don't merge in any KIM stuff yet, refactor out generic logic at some point
import { useMemo } from 'react';

import { ColumnDef, Row } from '@tanstack/react-table';
import { APIBaseChronos } from 'api/hosts';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { ReactComponent as ReactInfoYellow } from 'assets/info_yellow.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip } from 'react-tooltip';
import { ChronosDocumentCoordinate, ChronosFact } from 'types';

import TextInputCell from '../../components/TextInputCell';
import TooltipIcon from '../../FactsEditor/components/TooltipIcon';
import { useDocumentNavigation } from '../../FactsEditor/utils/navigationUtils';

const useGetFactColumns = (index: number = 0): ColumnDef<ChronosFact>[] => {
  const { goToDocReference } = useDocumentNavigation();

  const dateSortingFn = (rowA: Row<ChronosFact>, rowB: Row<ChronosFact>, columnId: string) => {
    const valueA = rowA.getValue(columnId) as string;
    const valueB = rowB.getValue(columnId) as string;

    const dateA = valueA ? new Date(valueA).getTime() : Number.MAX_SAFE_INTEGER;
    const dateB = valueB ? new Date(valueB).getTime() : Number.MAX_SAFE_INTEGER;

    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
  };

  const columns = useMemo<ColumnDef<ChronosFact, any>[]>(
    () => [
      {
        header: () => <div className="flex flex-row items-center pl-4">Id</div>,
        accessorKey: 'reference',
        maxSize: 20,
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { reference },
            },
          },
        }: any) => <div className="py-1 pl-2 font-bold">{reference}</div>,
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId={`date-subject-text-tooltip-${index}`}
              tooltipContent="Date of the fact"
              className="text-gray-700 mr-2 text-sm cursor-pointer ml-2"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            Date of Subject
          </div>
        ),
        accessorKey: 'date_of_subject',
        enableColumnFilter: false,
        enableSorting: true,
        sortingFn: dateSortingFn,
        minSize: 100,
        maxSize: 100,
        cell: ({
          cell: {
            row: {
              original: {
                event_id,
                date_of_subject_text,
                is_future_date,
                date_of_subject_is_ambiguous,
                date_of_subject_is_ambiguous_text,
              },
            },
          },
        }: any) => {
          return (
            <div className="flex flex-row items-center">
              {!!date_of_subject_is_ambiguous && (
                <ReactInfoYellow
                  data-tooltip-id={`fact-date-text-tooltip-${event_id}-ambiguous`}
                  data-tooltip-content={date_of_subject_is_ambiguous_text || 'Date format is ambiguous, please check'}
                  className="text-gray-700 mr-2 text-sm cursor-pointer"
                  style={{ color: '#E3B336' }}
                />
              )}
              {!!is_future_date && !date_of_subject_is_ambiguous && (
                <ReactInfo
                  data-tooltip-id={`fact-date-text-tooltip-${event_id}`}
                  data-tooltip-content="Hypothetical future date"
                  className="text-gray-700 mr-2 text-sm cursor-pointer"
                  style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
                />
              )}
              {!!is_future_date && <Tooltip id={`fact-date-text-tooltip-${event_id}`} style={TooltipStyles} />}
              {!!date_of_subject_is_ambiguous && (
                <Tooltip
                  id={`fact-date-text-tooltip-${event_id}-ambiguous`}
                  style={TooltipStyles}
                  place={'top-start'}
                />
              )}
              <TextInputCell
                editable={false}
                entryId={event_id}
                entryFieldKey={'date_of_subject_text'}
                entryFieldValue={date_of_subject_text}
                endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
              />
            </div>
          );
        },
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId={`actiondescribed-tooltip${index}`}
              tooltipContent="Description of the event or action taken"
              className="text-gray-700 mr-2 text-sm cursor-pointer ml-2"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            Action Described
          </div>
        ),
        accessorKey: 'action_described',
        minSize: 250,
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { action_described, event_id },
            },
          },
        }: any) => (
          <div className="py-1 text-sm">
            <TextInputCell
              editable={true}
              entryId={event_id}
              entryFieldKey={'action_described'}
              entryFieldValue={action_described}
              endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId={`sourcedoc-tooltip-${index}`}
              tooltipContent="Document from which fact was extracted, click to view source text"
              className="text-gray-700 mr-2 text-sm cursor-pointer  ml-2"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            Documents
          </div>
        ),
        minSize: 100,
        size: 100,
        maxSize: 100,
        accessorKey: 'source_doc',
        cell: ({
          cell: {
            row: {
              original: { documents, event_id },
            },
          },
        }: any) => (
          <div className="flex flex-col">
            {documents.map((document: ChronosDocumentCoordinate, idx: number) => {
              const pageNumber = document.coordinate_details[0]?.n_page || 1;
              const file = document?.file_name?.split('.')?.filter(Boolean)[0];
              return (
                <div
                  key={document.doc_id}
                  className="cursor-pointer text-blue-500 hover:underline hover:text-blue-600 text-ellipsis overflow-hidden "
                  onClick={(e) => {
                    e.stopPropagation();
                    goToDocReference(document.doc_id, event_id, pageNumber);
                  }}
                  data-tooltip-id={`sourcedoc-link-tooltip-${event_id}-${idx}`}
                  data-tooltip-content={file}
                >
                  <Tooltip id={`sourcedoc-link-tooltip-${event_id}-${idx}`} style={TooltipStyles} />
                  {file}
                </div>
              );
            })}
          </div>
        ),
      },

      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId={`significance-tooltip-${index}`}
              tooltipContent="A short analysis of the significance of the fact as it pertains to the synopsis and the context of the document it is extracted from."
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            Significance
          </div>
        ),
        minSize: 300,
        accessorKey: 'relevance_reason',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { event_id, relevance_reason },
            },
          },
        }: any) => (
          <div className="py-2">
            <TextInputCell
              className="text-sm"
              editable={true}
              entryId={event_id}
              entryFieldKey={'relevance_reason'}
              entryFieldValue={relevance_reason}
              endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    [],
  );

  return columns;
};

export default useGetFactColumns;
