import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ColumnFiltersState,
  ColumnSizingState,
  OnChangeFn,
  PaginationState,
  SortingState,
  Updater,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CheckState, ChronosDoc, ChronosFact } from 'types';

import {
  LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING,
  LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY,
  initialSortingState,
  initialVisibilityState,
} from './constants';
import FactsEditorTable from './FactsEditorTable';
import FactsEditorToolbar from './FactsEditorToolbar';
import useColumnState from './hooks/useColumnState';
import useFactsData from './hooks/useFactsData';
import useKeyFacts from './hooks/useKeyFacts';
import useToggleAll from './hooks/useToggleAllIncluded';
import useGetFactColumns from './useGetFactColumns';
import useFetchDocs from '../DocumentsEditor/hooks/useFetchDocs';

interface FactsEditorProps {
  docId?: string;
  onlyTable?: boolean;
}

interface CheckedRows {
  [key: string]: CheckState;
}

const FactsEditor = ({ docId }: FactsEditorProps) => {
  const [globalFilterInput, setGlobalFilterInput] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');

  // Query params
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  // Key fact filters MAYBE WE DON'T NEED THIS ANYMORE WITH NEW FETCH??
  const [keyFactFilterActive, setKeyFactFilterActive] = useState(false);
  const initialKeyFactIds = useMemo(() => {
    if (searchParams.get('keyFactIds')) {
      setKeyFactFilterActive(true);
      return searchParams
        .get('keyFactIds')
        ?.split(',')
        .map((id) => ({ label: id, value: id }));
    } else {
      return [];
    }
  }, [searchParams]);
  const [keyFactIds, setKeyFactIds] = useState(initialKeyFactIds);

  const initialColumnFilters = useMemo(() => {
    if (keyFactIds) {
      return [{ id: 'event_id', value: keyFactIds }];
    }
    return [];
  }, [keyFactIds]);

  //Check for page number in URL
  const pageNumber = searchParams.get('page') || '0';

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialColumnFilters);
  const [facts, setFacts] = useState<ChronosFact[]>([]);
  const [originalFacts, setOriginalFacts] = useState<ChronosFact[]>([]);
  const [sorting, setSorting] = useState<SortingState>(initialSortingState);
  const [checkedRows, setCheckedRows] = useState<CheckedRows>({});
  const [isDateDirty, setIsDateDirty] = useState(false);
  const [isLoadingFacts, setIsLoadingFacts] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string>(''); // State to hold current user ID
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });
  const [totalNumberOfFacts, setTotalNumberOfFacts] = useState(0);
  const [totalNumberOfIncludedFacts, setTotalNumberOfIncludedFacts] = useState(0);

  // Custom hooks
  const { columnSizing, setColumnSizing, columnVisibility, setColumnVisibility } = useColumnState();
  const { fetchConfigGET } = useGetFetchConfig();
  const putIncludedToggle = useToggleAll();

  // Data - facts, key facts
  const {
    data: responseFacts,
    isLoading,
    isFetching,
    refetch: refetchCaseFacts,
  } = useFactsData(docId, caseId, globalFilter, pagination.pageIndex + 1, pagination.pageSize, sorting, columnFilters);
  const { data: keyFactsData } = useKeyFacts(caseId);

  const { data: responseDocs, refetch: refetchCaseDocs } = useFetchDocs(caseId);

  // Table setup ---------------------------------------------------------------
  const columns = useGetFactColumns({
    caseId: caseId || '',
    currentUserId: currentUserId || '',
    setIsDateDirty,
    callback: refetchCaseFacts,
  });

  const hasActiveFilters = useMemo(() => columnFilters.some((filter) => filter.value), [columnFilters]);

  const handleUpdaterOrValue = <T,>(updaterOrValue: Updater<T>, currentValue: T): T => {
    return typeof updaterOrValue === 'function' ? (updaterOrValue as (val: T) => T)(currentValue) : updaterOrValue;
  };

  const onColumnSizingChange: OnChangeFn<ColumnSizingState> = (updaterOrValue) => {
    const columnSize: ColumnSizingState = handleUpdaterOrValue(updaterOrValue, columnSizing);
    const newState = { ...columnSizing, ...columnSize };

    setColumnSizing(newState);
    localStorage.setItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING, JSON.stringify(newState));
  };

  const onColumnVisibilityChange: OnChangeFn<VisibilityState> = (updaterOrValue) => {
    const newColumnVisibility: VisibilityState = handleUpdaterOrValue(updaterOrValue, columnVisibility);
    const newState = { ...columnVisibility, ...newColumnVisibility };

    setColumnVisibility(newState);
    localStorage.setItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY, JSON.stringify(newState));
  };

  const onColumnFiltersChange: OnChangeFn<ColumnFiltersState> = (updaterOrValue) => {
    const newColumnFilters: ColumnFiltersState = handleUpdaterOrValue(updaterOrValue, columnFilters);
    setColumnFilters(newColumnFilters);
  };

  const resetVisibleColumns = useCallback(() => {
    const resetValue = {};
    localStorage.setItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY, JSON.stringify(resetValue));
    setColumnVisibility(resetValue);
  }, [setColumnVisibility]);

  const clearAllFilters = useCallback(() => setColumnFilters([]), []);
  const clearSearch = async () => {
    setGlobalFilterInput('');
    setGlobalFilter('');
    await new Promise((resolve) => setTimeout(resolve, 0));
    refetchCaseFacts();
  };

  const handleGlobalFilterChange = (value: string) => {
    setGlobalFilterInput(value);
  };

  const table = useReactTable<ChronosFact>({
    columns,
    data: facts,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange,
    columnResizeMode: 'onChange',
    getRowId: (row) => row.event_id,
    onColumnSizingChange,
    enableMultiSort: true,
    onColumnFiltersChange,
    state: {
      columnSizing,
      columnVisibility,
      pagination: {
        pageIndex: 0,
        pageSize: pagination.pageSize,
      },
    },
  });

  const allRowsChecked = useMemo(
    () => table.getFilteredRowModel().rows.every((r) => checkedRows[r.id]?.isIncluded),
    // eslint-disable-next-line
    [checkedRows, columnFilters],
  );
  const anyRowsChecked = useMemo(
    () => table.getFilteredRowModel().rows.some((r) => checkedRows[r.id]?.isIncluded),
    // eslint-disable-next-line
    [checkedRows, columnFilters],
  );
  // ---------------------------------------------------------------------------

  // useEffects
  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const res = await fetch(`${APIBaseChronos}/client/user/user`, fetchConfigGET);
        if (!res.ok) throw new Error('Network response was not ok');
        const data = await res.json();
        setCurrentUserId(data.user.userId);
      } catch (error) {
        console.error('Error fetching current user ID:', error);
      }
    };

    fetchCurrentUser();
  }, [fetchConfigGET]);

  useEffect(() => {
    const columnSizingString = localStorage.getItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_SIZING);
    const columnVisibilityString = localStorage.getItem(LOCAL_STORAGE_FACT_TABLE_COLUMN_VISIBILITY);

    if (columnSizingString) setColumnSizing(JSON.parse(columnSizingString));
    if (columnVisibilityString) setColumnVisibility(JSON.parse(columnVisibilityString) || initialVisibilityState);
  }, [setColumnSizing, setColumnVisibility]);

  useEffect(() => {
    setIsLoadingFacts(isLoading);
  }, [isLoading]);

  useEffect(() => {
    refetchCaseFacts();
    // eslint-disable-next-line
  }, [pagination.pageIndex, sorting, columnFilters]);

  useEffect(() => {
    refetchCaseDocs();
    // eslint-disable-next-line
  }, []);

  // Post process facts after they are fetched
  useEffect(() => {
    if (responseFacts?.facts) {
      setTotalNumberOfFacts(responseFacts.totalFactsCount);
      setTotalNumberOfIncludedFacts(responseFacts.includedFactsCount);

      const modifiedFacts = responseFacts.facts.map((fact: ChronosFact) => ({
        ...fact,
        source_doc: fact.documents.map((doc) => doc.doc_id).join(','),
      }));

      const newCheckedState: CheckedRows = responseFacts.facts.reduce((acc, row) => {
        acc[row.event_id] = {
          isIncluded: row.included,
          isVerified: row.verified,
          verified_by_email: row.verified_by_email,
          verified_date: row.verified_date,
          first_comment: row.first_comment,
          comments_count: parseInt(row.comments_count),
        };
        return acc;
      }, {} as CheckedRows);

      setFacts(modifiedFacts);
      setOriginalFacts(responseFacts?.allFacts);
      setCheckedRows(newCheckedState);
    }
    setIsDateDirty(false);
    setIsLoadingFacts(false);
  }, [responseFacts]);

  // Handlers
  const handleToggleAll = (toggleValue: boolean): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      if (!caseId) {
        reject(new Error('No caseId provided'));
        return;
      }

      // Construct filter parameters
      const filterParams = columnFilters
        .map((filter) => {
          if (Array.isArray(filter.value)) {
            return `filter_${filter.id}=${filter.value.map((val) => val.value).join(',')}`;
          }
          return `filter_${filter.id}=${filter.value}`;
        })
        .join('&');

      try {
        await putIncludedToggle.mutateAsync({ caseId, toggleValue, filterParams: filterParams });
        await refetchCaseFacts();
        resolve();
      } catch (err) {
        Swal.fire({
          title: 'Error on update',
          text: 'There was an error updating the fields. Please try again later.',
          icon: 'error',
          showConfirmButton: false,
          timer: 3000,
        });
        reject(err);
      }
    });
  };

  const onSearchCall = async () => {
    if (!docId) {
      // Reset page to 0
      const newPaginationState = { pageIndex: 0, pageSize: pagination.pageSize };
      setPagination(newPaginationState);

      setGlobalFilter(globalFilterInput); // Set the actual filter used for fetching
      await new Promise((resolve) => setTimeout(resolve, 0));
      refetchCaseFacts(); // Trigger the refetch
    }
  };

  const handleKeyFactFilterToggle = async () => {
    // If keyFactFilterActive is currently true, then remove the event_id filter from the columnFilters
    if (keyFactFilterActive) {
      setKeyFactFilterActive(!keyFactFilterActive);
      const newColumnFilters = columnFilters.filter((filter) => filter.id !== 'event_id');
      setColumnFilters(newColumnFilters);
      setKeyFactIds([]);
    } else {
      // If keyFactFilterActive is currently false, then fetch key fact event_ids and add to columnFilters
      setKeyFactFilterActive(!keyFactFilterActive);
      if (keyFactsData?.event_ids) {
        goToPage(0);
        const keyFactFilters = keyFactsData.event_ids.map((id) => ({ label: id, value: id }));
        const newColumnFilters = [{ id: 'event_id', value: keyFactFilters }];
        setColumnFilters(newColumnFilters);
      }
    }
  };

  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };

  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  const totalPages = Math.ceil((totalNumberOfFacts || 0) / pagination.pageSize);

  return (
    <>
      <FactsEditorToolbar
        docId={docId}
        caseId={caseId}
        facts={facts}
        originalFacts={originalFacts}
        checkedRows={checkedRows}
        isLoadingFacts={isLoadingFacts}
        isLoading={isLoading || isFetching}
        globalFilter={globalFilterInput}
        clearSearch={clearSearch}
        setGlobalFilter={handleGlobalFilterChange}
        refetchCaseFacts={refetchCaseFacts}
        columns={table.getAllFlatColumns()}
        resetVisibleColumns={resetVisibleColumns}
        resultsLength={totalNumberOfFacts}
        resultsIncludedLength={totalNumberOfIncludedFacts}
        canGetPrevPage={pagination.pageIndex > 0}
        canGetNextPage={pagination.pageIndex < totalPages - 1}
        prevPage={prevPage}
        nextPage={nextPage}
        goToPage={goToPage}
        currentPage={pagination.pageIndex}
        noOfPages={totalPages}
        isDateDirty={isDateDirty}
        onSearchCall={onSearchCall}
        keyFactFilterActive={keyFactFilterActive}
        handleKeyFactFilterToggle={handleKeyFactFilterToggle}
      />
      <FactsEditorTable
        serverSorting={responseFacts?.appliedSortings || []}
        serverFilters={responseFacts?.appliedFilters || []}
        setSorting={setSorting}
        isLoadingFacts={isLoadingFacts}
        getRowModel={table.getRowModel}
        getCenterTotalSize={table.getCenterTotalSize}
        getHeaderGroups={table.getHeaderGroups}
        allRowsChecked={allRowsChecked}
        anyRowsChecked={anyRowsChecked}
        handleToggleAll={handleToggleAll}
        clearAllFilters={clearAllFilters}
        hasActiveFilters={hasActiveFilters}
        keyFactIds={new Set(keyFactsData?.event_ids) || new Set()}
        allDocuments={(responseDocs?.docs as ChronosDoc[]) || []}
      />
    </>
  );
};

export default FactsEditor;
