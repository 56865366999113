import React, { useState, ReactNode } from 'react';

import { faCheck, faCopy, faComment, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from 'components/molecules/Accordian';

import formatSummary from '../../helpers/formatHTML';

const ThemeContent = ({
  content,
  subquestions,
  id,
}: {
  content: string | ReactNode;
  subquestions: { title: string; message_content: string }[];
  id?: string;
}) => {
  const [copyClicked, setCopyClicked] = useState(false);

  const handleCopyClick = () => {
    setCopyClicked(true);
    setTimeout(() => {
      setCopyClicked(false);
    }, 2000);
  };

  return (
    <div className="mt-2 text-gray-800 text-base leading-relaxed ">
      <div className="pr-2">
        <p id={`${id}-text`}>{content}</p>
        <div className="text-gray-800 flex gap-2 mt-4 mb-8">
          <div
            onClick={handleCopyClick}
            className="flex items-center gap-2 border hover:bg-gray-200 hover:cursor-pointer rounded-full px-3 py-2"
          >
            {copyClicked ? (
              <>
                <FontAwesomeIcon icon={faCheck} className="w-4 h-4 cursor-pointer" />
                <span className="text-xs">Copied!</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCopy} className="w-4 h-4 cursor-pointer" />
                <span className="text-xs">Copy</span>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="text-lg font-semibold pl-4 mb-4">
        <FontAwesomeIcon icon={faComment} className="text-gray-800 w-5 h-5 cursor-pointer mr-2" />
        Related Questions
      </div>

      <div className="flex flex-col mb-8">
        {subquestions.map((subquestion, index) => (
          <div id={`${id}-subquestion-${index}`}>
            <Accordion
              title={<p className="hover:text-blue-500 pr-12 text-sm">{subquestion.title}</p>}
              content={<SubquestionContent content={formatSummary(subquestion.message_content, () => {})} />}
              icon={<FontAwesomeIcon icon={faPlus} className="text-blue-500 transition-transform duration-300" />}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const SubquestionContent = ({ content }: { content: string | ReactNode }) => {
  const [copyClicked, setCopyClicked] = useState(false);

  const handleCopyClick = () => {
    setCopyClicked(true);
    setTimeout(() => {
      setCopyClicked(false);
    }, 2000);
  };

  return (
    <div className="pr-2 mt-2 text-gray-800 text-base leading-relaxed">
      <p>{content}</p>
      <div className="flex gap-2 mt-4 mb-6">
        <div
          onClick={handleCopyClick}
          className="flex items-center gap-2 border hover:bg-gray-200 hover:cursor-pointer rounded-full px-3 py-2"
        >
          {copyClicked ? (
            <>
              <FontAwesomeIcon icon={faCheck} className="w-4 h-4 cursor-pointer" />
              <span className="text-xs">Copied!</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCopy} className="w-4 h-4 cursor-pointer" />
              <span className="text-xs">Copy</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThemeContent;
