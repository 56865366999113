import { Dispatch, SetStateAction, useMemo } from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { ReactComponent as ReactInfo } from 'assets/info.svg';
import { ReactComponent as ReactInfoYellow } from 'assets/info_yellow.svg';
import { IMPORTANCY_OPTIONS } from 'constants/chronos_table_options';
import { TooltipStyles } from 'constants/styles';
import { getFileNameWithExtension } from 'helpers/filenames';
import { Tooltip } from 'react-tooltip';

import TooltipIcon from './components/TooltipIcon'; // Adjust the import path as necessary
import filterFns from './filters';
import { useDocumentNavigation } from './utils/navigationUtils';
import { APIBaseChronos } from '../../../../api/hosts';
import { ChronosDocumentCoordinate, ChronosFact } from '../../../../types';
import CommentCell from '../components/CommentCell';
import { default as SelectInputCell } from '../components/ContentiousInputCell';
import DateInputCell from '../components/DateInputCell';
import IncludeCheckbox from '../components/IncludeCheckbox';
import TextFieldWithDatePicker from '../components/TextFieldWithDatePicker';
import TextInputCell from '../components/TextInputCell';
import DateCell from '../DocumentsEditor/DateCell';

interface useGetFactColumnsProps {
  caseId: string;
  currentUserId: string;
  setIsDateDirty: Dispatch<SetStateAction<boolean>>;
  callback: () => void;
}

export const factColumnLabels: Record<string, string> = {
  date_of_subject_text: 'Date',
  action_described: 'Action Described',
  subject_matter: 'Subject Matter',
  source_text: 'Source Text',
  source_doc: 'Source Doc',
  document_date_text: 'Document Date',
  date_uploaded: 'Upload Date',
  important: 'Important',
  included: 'Included',
  relevance_reason: 'Significance',
  event_id: 'Event Id',
  comment: 'Notes',
  classification: 'Classification',
  comment_thread_id: 'Notes',
};

const useGetFactColumns = ({ caseId, currentUserId, setIsDateDirty, callback }: useGetFactColumnsProps) => {
  const { goToDocReference } = useDocumentNavigation();

  const columns = useMemo<ColumnDef<ChronosFact, any>[]>(
    () => [
      {
        header: () => {
          return <div className="flex flex-row items-start justify-center pr-1">{factColumnLabels.included}</div>;
        },
        minSize: 20,
        maxSize: 20,
        accessorKey: 'included',
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({
          cell: {
            row: {
              original: { included, event_id, editor_id_email },
            },
          },
        }: any) => {
          return (
            <IncludeCheckbox
              included={included}
              eventId={event_id}
              caseId={caseId}
              editorIdEmail={editor_id_email}
              callback={callback}
            />
          );
        },
      },
      {
        header: () => (
          <div className="flex flexrow items-center">
            <TooltipIcon
              tooltipId="date-subject-tooltip"
              tooltipContent="Date of the fact"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            Date
          </div>
        ),
        accessorKey: 'date_of_subject',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { date_of_subject, event_id },
            },
          },
        }: any) => (
          <DateInputCell
            editable={true}
            entryId={event_id}
            entryFieldKey={'date_of_subject'}
            entryFieldValue={date_of_subject}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="event_id"
              tooltipContent="Event Id"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            {factColumnLabels.event_id}
          </div>
        ),
        minSize: 128,
        size: 128,
        maxSize: 128,
        enableColumnFilter: false,
        accessorKey: 'event_id',
        cell: ({
          cell: {
            row: {
              original: { event_id },
            },
          },
        }: any) => (
          <div className="flex flex-col w-32">
            <div
              key={event_id}
              className="text-ellipsis overflow-hidden whitespace-nowrap"
              data-tooltip-id={`event-id-${event_id}`}
              data-tooltip-content={event_id}
            >
              <Tooltip id={`event-id-${event_id}`} style={{ ...TooltipStyles, width: '250px' }} place="top-start" />
              {event_id}
            </div>
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="date-subject-text-tooltip"
              tooltipContent="Date of the fact"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />

            {factColumnLabels.date_of_subject_text}
          </div>
        ),
        accessorKey: 'date_of_subject_text',
        enableColumnFilter: false,
        minSize: 80,
        cell: ({
          cell: {
            row: {
              original: {
                event_id,
                date_of_subject_text,
                is_future_date,
                date_of_subject_is_ambiguous,
                date_of_subject_is_ambiguous_text,
              },
            },
          },
        }: any) => {
          return (
            <div className="flex flex-row items-center w-40">
              {!!date_of_subject_is_ambiguous && (
                <ReactInfoYellow
                  data-tooltip-id={`fact-date-text-tooltip-${event_id}-ambiguous`}
                  data-tooltip-content={date_of_subject_is_ambiguous_text || 'Date format is ambiguous, please check'}
                  className="text-gray-700 mr-2 text-sm cursor-pointer"
                  style={{ color: '#E3B336' }}
                />
              )}
              {!!is_future_date && !date_of_subject_is_ambiguous && (
                <ReactInfo
                  data-tooltip-id={`fact-date-text-tooltip-${event_id}`}
                  data-tooltip-content="Hypothetical future date"
                  className="text-gray-700 mr-2 text-sm cursor-pointer"
                  style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
                />
              )}
              {!!is_future_date && <Tooltip id={`fact-date-text-tooltip-${event_id}`} style={TooltipStyles} />}
              {!!date_of_subject_is_ambiguous && (
                <Tooltip
                  id={`fact-date-text-tooltip-${event_id}-ambiguous`}
                  style={TooltipStyles}
                  place={'top-start'}
                />
              )}
              <TextFieldWithDatePicker
                entryId={event_id}
                entryFieldKey={'date_of_subject_text'}
                entryFieldValue={date_of_subject_text}
                endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
                setIsDateDirty={setIsDateDirty}
              />
            </div>
          );
        },
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="actiondescribed-tooltip"
              tooltipContent="Description of the event or action taken"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            {factColumnLabels.action_described}
          </div>
        ),
        accessorKey: 'action_described',
        minSize: 320,
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { action_described, event_id },
            },
          },
        }: any) => (
          <div className="py-2">
            <TextInputCell
              className={'max-w-md text-sm'}
              editable={true}
              entryId={event_id}
              entryFieldKey={'action_described'}
              entryFieldValue={action_described}
              endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="subject-matter-tooltip"
              tooltipContent="High level subject matter of the fact within the dispute"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            {factColumnLabels.subject_matter}
          </div>
        ),
        minSize: 150,
        accessorKey: 'subject_matter',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { subject_matter, event_id },
            },
          },
        }: any) => (
          <TextInputCell
            editable={true}
            entryId={event_id}
            entryFieldKey={'subject_matter'}
            entryFieldValue={subject_matter}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="source-text-tooltip"
              tooltipContent="Source text of the event"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            {factColumnLabels.source_text}
          </div>
        ),
        minSize: 150,
        accessorKey: 'source_text',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { source_text, event_id },
            },
          },
        }: any) => (
          <TextInputCell
            html={true}
            className={'max-w-md text-sm'}
            editable={false}
            entryId={event_id}
            entryFieldKey={'source_text'}
            entryFieldValue={source_text || ''}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="sourcedoc-tooltip"
              tooltipContent="Document from which fact was extracted, click to view source text"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            {factColumnLabels.source_doc}
          </div>
        ),
        minSize: 140,
        size: 140,
        maxSize: 140,
        accessorKey: 'source_doc',
        enableSorting: false,
        filterFn: filterFns.multiSelectDoc,
        cell: ({
          cell: {
            row: {
              original: { documents, event_id },
            },
          },
        }: any) => (
          <div className="flex flex-col w-32">
            {documents.map((document: ChronosDocumentCoordinate, idx: number) => {
              const pageNumber = document.coordinate_details[0]?.n_page || 1;
              const file = getFileNameWithExtension(document?.file_name);
              return (
                <div
                  key={document.doc_id}
                  className="cursor-pointer text-blue-500 hover:underline hover:text-blue-600 text-ellipsis overflow-hidden whitespace-nowrap"
                  onClick={() => goToDocReference(document.doc_id, event_id, pageNumber)}
                  data-tooltip-id={`sourcedoc-link-tooltip-${event_id}-${idx}`}
                  data-tooltip-content={file}
                >
                  <Tooltip id={`sourcedoc-link-tooltip-${event_id}-${idx}`} style={TooltipStyles} />
                  {file}
                </div>
              );
            })}
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="document-date-tooltip"
              tooltipContent="Document Date"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />

            {factColumnLabels.document_date_text}
          </div>
        ),
        minSize: 100,
        accessorKey: 'document_date',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { document_date, event_id },
            },
          },
        }: any) => (
          <div className="flex flex-row items-center">
            <DateInputCell
              editable={true}
              entryId={event_id}
              entryFieldKey={'document_date'}
              entryFieldValue={document_date}
              endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <p>{factColumnLabels.date_uploaded}</p>
          </div>
        ),
        accessorKey: 'date_uploaded',
        enableColumnFilter: false,
        minSize: 130,
        cell: ({
          cell: {
            row: {
              original: { date_uploaded },
            },
          },
        }: any) => <DateCell format={'DD MMMM YYYY'} date={date_uploaded} />,
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="document-date-text-tooltip"
              tooltipContent="Document Date"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />

            {factColumnLabels.document_date_text}
          </div>
        ),
        minSize: 100,
        accessorKey: 'document_date_text',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { event_id, document_date_text, document_date_is_ambiguous, document_date_is_ambiguous_text },
            },
          },
        }: any) => {
          return (
            <div className="flex flex-row ">
              {!!document_date_is_ambiguous && (
                <ReactInfoYellow
                  data-tooltip-id={`document-date-text-tooltip-${event_id}-ambiguous`}
                  data-tooltip-content={document_date_is_ambiguous_text || 'Date format is ambiguous, please check'}
                  className="text-gray-700 mr-2 text-sm cursor-pointer"
                  style={{ color: '#E3B336' }}
                />
              )}
              {!!document_date_is_ambiguous && (
                <Tooltip
                  id={`document-date-text-tooltip-${event_id}-ambiguous`}
                  style={TooltipStyles}
                  place={'top-start'}
                />
              )}
              <TextInputCell
                editable={true}
                entryId={event_id}
                entryFieldKey={'document_date_text'}
                entryFieldValue={document_date_text}
                endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
              />
            </div>
          );
        },
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="important-tooltip"
              tooltipContent="Is this fact important to the case, as extracted by Wexler"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />

            {factColumnLabels.important}
          </div>
        ),
        minSize: 100,
        accessorKey: 'important',
        filterFn: filterFns.stringMatch,
        enableColumnFilter: true,
        cell: ({
          cell: {
            row: {
              original: { important, event_id },
            },
          },
        }: any) => (
          <SelectInputCell
            className={'mr-5'}
            entryId={event_id}
            entryFieldKey={'important'}
            entryFieldValue={important}
            endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
            options={IMPORTANCY_OPTIONS}
          />
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="significance-tooltip"
              tooltipContent="A short analysis of the significance of the fact as it pertains to the synopsis and the context of the document it is extracted from."
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />

            {factColumnLabels.relevance_reason}
          </div>
        ),
        minSize: 450,
        maxSize: 800,
        accessorKey: 'relevance_reason',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { event_id, relevance_reason },
            },
          },
        }: any) => (
          <div className="py-2 text-sm">
            <TextInputCell
              editable={true}
              entryId={event_id}
              entryFieldKey={'relevance_reason'}
              entryFieldValue={relevance_reason}
              endpointToUpdate={`${APIBaseChronos}/client/case/fact/${event_id}`}
            />
          </div>
        ),
      },
      {
        header: () => (
          <div className="flex flex-row items-center">
            <TooltipIcon
              tooltipId="classification"
              tooltipContent="Classification"
              className="text-gray-700 mr-2 text-sm cursor-pointer"
              style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
            />
            Classification
          </div>
        ),
        filterFn: filterFns.stringMatch,
        minSize: 180,
        size: 180,
        maxSize: 180,
        enableColumnFilter: true,
        accessorKey: 'classification',
        cell: ({
          cell: {
            row: {
              original: { event_id, classification },
            },
          },
        }: any) => (
          <div className="flex flex-col w-32">
            <div key={event_id + '_' + classification} className="text-ellipsis overflow-hidden whitespace-nowrap">
              {classification ? `${classification.charAt(0).toUpperCase() + classification.slice(1)}` : ''}
            </div>
          </div>
        ),
      },
      {
        header: () => {
          return <div className="flex flex-row items-start justify-center">{factColumnLabels.comment}</div>;
        },
        minSize: 300,
        accessorKey: 'comment_thread_id',
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({
          cell: {
            row: {
              original: { event_id, first_comment, comments_count },
            },
          },
        }: any) => {
          return (
            <CommentCell
              thread_id={event_id}
              comment={first_comment}
              comments_count={comments_count && parseInt(comments_count)}
              setCheckedRows={() => {}}
              currentUserId={currentUserId}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line
    [],
  );

  return columns;
};

export default useGetFactColumns;
