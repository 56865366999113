import React, { Dispatch, useEffect, SetStateAction, useRef, useState } from 'react';

import { faCalendarDays, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { TooltipStyles } from 'constants/styles';
import DatePicker from 'react-datepicker';
import { useMutation } from 'react-query';
import { Tooltip } from 'react-tooltip';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

interface TextFieldWithDatePickerProps {
  entryId: string;
  entryFieldKey: string;
  entryFieldValue: string;
  endpointToUpdate: string;
  setIsDateDirty?: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
}

const TextFieldWithDatePicker: React.FC<TextFieldWithDatePickerProps> = ({
  entryId,
  entryFieldKey,
  entryFieldValue,
  endpointToUpdate,
  setIsDateDirty,
  callback,
}) => {
  const [text, setText] = useState<string>(entryFieldValue);
  const [originalText, setOriginalText] = useState<string>(entryFieldValue);
  const [date, setDate] = useState<Date | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const { getFetchConfig } = useGetFetchConfig();

  const mutation = useMutation(
    async (newValue: string) => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { [entryFieldKey]: newValue } });
      const response = await fetch(endpointToUpdate, fetchConfig);
      if (!response.ok) {
        throw new Error('Failed to update');
      }
      return response.json();
    },
    {
      onSuccess: () => {
        if (setIsDateDirty) setIsDateDirty(true);
        if (callback) callback();
      },
      onError: (error: any) => {
        console.error('Fetch Error: ', error);
        Swal.fire({
          title: 'Error on update',
          text: 'There was an error updating the fields. Please try again later.',
          showConfirmButton: false,
          timer: 3000,
        });
      },
    },
  );

  // Function to handle text change
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  // Function to handle date change
  // Function to handle date change
  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      // Format the date as "dd MMM yyyy"
      const formattedDate = newDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });

      setText(formattedDate);
      setDate(newDate);
      setIsEditing(false);
      if (formattedDate !== originalText) {
        mutation.mutate(formattedDate);
        setOriginalText(formattedDate);
      }
    }
  };

  // Function to handle pressing Enter in the text field
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      if (text !== originalText) {
        mutation.mutate(text);
        setOriginalText(text); // Update the original text to the new value
      }
    }
  };

  // Toggle editing mode
  const toggleEditing = () => {
    setIsEditing((prev) => !prev);
  };

  // Handle click outside to exit editing mode
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        if (text !== originalText) {
          mutation.mutate(text);
          setOriginalText(text);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [originalText, mutation, text]);

  // Custom input component for DatePicker
  const CustomInput = ({ onClick }: { onClick: () => void }) => (
    <div>
      <FontAwesomeIcon icon={faCalendarDays} onClick={onClick} className="text-gray-700 cursor-pointer w-4 h-4" />
    </div>
  );

  return (
    <div className="flex items-center gap-2 w-full" ref={inputRef}>
      <Tooltip id={`fact-date-text-tooltip-${entryId}`} style={TooltipStyles} place={'top-start'} />
      {isEditing ? (
        <div
          className="flex items-center gap-2"
          data-tooltip-id={`fact-date-text-tooltip-${entryId}`}
          data-tooltip-content="Type a verbatim date or date range into the text field, or select a specific date by clicking the calendar."
        >
          <DatePicker
            dateFormat="MMMM d, yyyy h:mm aa"
            selected={date}
            onChange={handleDateChange}
            customInput={<CustomInput onClick={() => {}} />}
          />
          <input
            className="p-1 w-[120px] rounded-md"
            type="text"
            value={text}
            onChange={handleTextChange}
            onKeyDown={handleKeyPress}
            placeholder="Date..."
            autoFocus
          />
        </div>
      ) : (
        <div className="flex items-center w-full justify-between">
          <span>{text || ''}</span>
          <FontAwesomeIcon
            icon={faPencil}
            onClick={toggleEditing}
            className="text-gray-700 cursor-pointer w-4 h-4 ml-2"
          />
        </div>
      )}
    </div>
  );
};

export default TextFieldWithDatePicker;
