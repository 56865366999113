import { useMemo } from 'react';
import React from 'react';

import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { StageSpinner } from 'react-spinners-kit';
import { ChronosRun } from 'types';

import { HeaderContent } from './HeaderContent';
import useGetDocumentColumns from './useGetDocumentColumns';
interface RunsViewProps {
  runs: ChronosRun[];
  isLoadingRuns: boolean;
}

const RunsView = React.memo(({ runs, isLoadingRuns }: RunsViewProps) => {
  const columns = useGetDocumentColumns();

  const data = useMemo(
    () =>
      runs.map((run, idx) => ({
        ...run,
        item_number: idx,
      })),
    [runs],
  );

  const table = useReactTable<ChronosRun>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const rowsToRender = table.getRowModel().rows;

  return (
    <>
      <div className="w-full flex flex-row items-center justify-between mt-4"></div>
      {isLoadingRuns && (
        <div className="w-full h-24 flex items-center justify-center">
          <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
        </div>
      )}
      <div
        className="w-full flex flex-column overflow-scroll  max-h-[calc(100vh-250px)]"
        style={{
          borderRadius: '16px',
          border: '1px solid var(--colors-primary-slate-100, #E9EFF6)',
          background: '#FFF',
        }}
      >
        {runs && runs.length > 0 && (
          <div className="w-full">
            <table className="w-full">
              <thead className="h-12" style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)' }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header: any) => {
                      return (
                        <th
                          key={header.id}
                          className="not-italic font-medium"
                          style={{ width: header.column.getSize(), color: 'var(--colors-primary-slate-400, #8897AE)' }}
                        >
                          <HeaderContent header={header} />
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {rowsToRender.map((row, i) => {
                  return (
                    <tr
                      style={{ background: i % 2 === 0 ? 'white' : '#F9FAFB', borderBottom: '1px solid #c8cad7' }}
                      key={row.id}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td className="pl-3" key={cell.id} id={cell.id} style={{ width: cell.column.getSize() }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
});

export default RunsView;
