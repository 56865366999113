import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import FileUploader from 'components/molecules/FileUploader';
import { niceBytes } from 'helpers/bytesCalculator';
import { getFileNameWithExtension } from 'helpers/filenames';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { ChronosDoc } from 'types';

import useGetFetchConfig from '../../../api/useGetFetchConfig';

class UploadError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

const DocAdder: React.FC = () => {
  const navigate = useNavigate();
  const [successfulUploads, setSuccessfulUploads] = useState<number>(0);
  const [expectedUploads, setExpectedUploads] = useState<number>(0);
  const [caseName, setCaseName] = useState<string>('');
  const [docsTab, setDocsTab] = useState<string>('active');
  const [posting, setPosting] = useState(false);
  const [docRemoving, setDocRemoving] = useState<string>('');
  const { caseId } = useParams();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const {
    isFetching: isLoadingNewCase,
    data: responseNewCase,
    refetch: refetchNewCase,
  } = useQuery(
    'getCaseForDocAdder',
    () => {
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
        .then((res) => res.json())
        .catch((err) => console.error(err));
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    isFetching: isLoadingUpdateCase,
    data: responseUpdateCase,
    refetch: refetchUpdateCase,
  } = useQuery(
    'addNewFiles',
    () => {
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { isUpdate: true } });
      return fetch(`${APIBaseChronos}/client/case/addNewFiles/${caseId}`, fetchConfig)
        .then((res) => res.json())
        .catch((err) => console.error(err));
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    data: responseDocs,
    refetch: refetchCaseDocs,
    isLoading: isLoadingDocuments,
  } = useQuery(
    ['userNewDocs'],
    () => fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => res.json()),
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (responseUpdateCase?.success) {
      navigate(`/app/chronos/case-editor/documents?caseId=${caseId}`);
    }
    // eslint-disable-next-line
  }, [responseUpdateCase]);

  useEffect(() => {
    refetchNewCase();
    refetchCaseDocs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (responseNewCase?.caseObject?.case_name) {
      setCaseName(responseNewCase?.caseObject?.case_name);
    }
  }, [responseNewCase]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setPosting(true);
      setSuccessfulUploads(0);
      setExpectedUploads(acceptedFiles.length);
      const uploadPromises = acceptedFiles.map((file) =>
        uploadFile(file)
          .then(() => setSuccessfulUploads((prev) => prev + 1))
          .catch((error) => {
            if (error instanceof UploadError) {
              Swal.fire({
                title: 'Error',
                text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }),
      );

      await Promise.allSettled(uploadPromises);

      refetchCaseDocs();
      setPosting(false);
    },
    // eslint-disable-next-line
    [caseId],
  );

  async function uploadFile(file: File): Promise<void> {
    if (caseId) {
      if (file.name.endsWith('.zip') || file.name.endsWith('.rar')) {
        Swal.fire({
          title: 'Unsupported File Type',
          text: `The file ${file.name} is not supported. Please upload files of type EML, MHT, DocX, Doc, PDF, and Spreadsheet files.`,
          showConfirmButton: false,
          timer: 3000,
        });
        // return; // Skip unsupported files
      }

      return new Promise<void>(async (resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('caseId', caseId);

            const fetchConfig = getFetchConfig({ method: 'POST', data: formData, isFormData: true });

            const endpoint = `client/case/doc`;
            await fetch(`${APIBaseChronos}/${endpoint}`, fetchConfig)
              .then(async (results) => {
                if (!results.ok) {
                  const errorData = await results.json();
                  console.log(errorData);
                  if (errorData.type === 'zero-byte-file') {
                    Swal.fire({
                      title: 'Error',
                      text: `The file ${file.name} is empty`,
                      showConfirmButton: false,
                      timer: 3000,
                    });
                  } else {
                    Swal.fire({
                      title: 'Error',
                      text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
                      showConfirmButton: false,
                      timer: 3000,
                    });
                  }
                  throw new UploadError(errorData.message || 'Error occurred while uploading file', results);
                }
              })
              .catch((error) => {
                console.log(error);
                reject(new Error(`The file ${file.name} could not be uploaded. Please try again later`));
              });
            resolve();
          } catch (error) {
            reject(error);
          }
        };
        if (file) {
          reader.readAsDataURL(file);
        }
      });
    }
  }

  const handleRemoveDoc = async (docId: string) => {
    setDocRemoving(docId);
    const fetchConfig = getFetchConfig({ method: 'DELETE' });

    await fetch(`${APIBaseChronos}/client/case/doc/${docId}`, fetchConfig);
    setDocRemoving('');
    refetchCaseDocs();
  };

  const handleUpdateCase = () => {
    refetchUpdateCase();
  };

  const handleClickBack = () => {
    Swal.fire({
      title: '',
      text: 'Are you sure you want to stop adding documents to the case?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'rgb(37 99 235)',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/app/chronos/case-editor/documents?caseId=${caseId}`);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  const docsInCase = useMemo(() => {
    return responseDocs?.docs.filter((doc: any) => doc.run_id != null) || [];
  }, [responseDocs?.docs]);

  const docsToRun = useMemo(() => {
    return responseDocs?.docs.filter((doc: any) => !doc.run_id) || [];
  }, [responseDocs?.docs]);

  // Splitting docs into active and removed
  const activeDocsAlreadyInCase = useMemo(() => docsInCase.filter((doc: ChronosDoc) => !doc.is_removed), [docsInCase]);
  const activeDocs = useMemo(() => docsToRun.filter((doc: ChronosDoc) => !doc.is_removed), [docsToRun]);
  const removedDocs = useMemo(() => docsToRun.filter((doc: ChronosDoc) => doc.is_removed), [docsToRun]);

  interface ExtendedChronosDoc extends ChronosDoc {
    isDuplicate?: boolean;
    isUnsupported?: boolean;
  }

  const markDuplicatesAndUnsupported = (
    docsArray: ChronosDoc[],
    groupedByHash: Record<string, ChronosDoc[]>,
  ): ExtendedChronosDoc[] => {
    return docsArray.map((doc) => ({
      ...doc,
      isDuplicate: groupedByHash[doc.file_hash]?.length > 1,
      alreadyExistsInCase: activeDocsAlreadyInCase.filter((docInCase: any) => docInCase.file_hash === doc.file_hash),
      isUnsupported: doc.file_extension?.toLowerCase() === 'zip' || doc.file_extension?.toLowerCase() === 'rar',
    }));
  };

  const groupDocsByHash = (docsArray: ChronosDoc[]): Record<string, ChronosDoc[]> => {
    return docsArray.reduce<Record<string, ChronosDoc[]>>((acc, doc) => {
      const key = doc.file_hash || 'undefined'; // Handle undefined file_hash appropriately
      (acc[key] = acc[key] || []).push(doc);
      return acc;
    }, {});
  };

  const sortDocs = (docsArray: ChronosDoc[]): ChronosDoc[] => {
    const groupedByHash = groupDocsByHash(docsArray);
    const markedDocs = markDuplicatesAndUnsupported(docsArray, groupedByHash);

    return markedDocs.sort((a, b) => {
      if (a.isUnsupported && !b.isUnsupported) return -1;
      if (!a.isUnsupported && b.isUnsupported) return 1;

      const aExistsInCase = a.alreadyExistsInCase.length > 0;
      const bExistsInCase = b.alreadyExistsInCase.length > 0;
      if (aExistsInCase && !bExistsInCase) return -1;
      if (!aExistsInCase && bExistsInCase) return 1;

      // Prioritize duplicates within the group that doesn't already exist in the case
      const aIsDuplicate = a.isDuplicate;
      const bIsDuplicate = b.isDuplicate;
      if (aIsDuplicate && !bIsDuplicate) return -1;
      if (!aIsDuplicate && bIsDuplicate) return 1;

      // Sort by file hash as a final tiebreaker
      if (!a.file_hash && b.file_hash) return 1;
      if (a.file_hash && !b.file_hash) return -1;
      return a.file_hash ? a.file_hash.localeCompare(b.file_hash || '') : 0;
    });
  };

  // Sorted and grouped active documents
  // eslint-disable-next-line
  const sortedActiveDocs = useMemo(() => sortDocs(activeDocs), [activeDocs]);
  const groupedActiveDocs = useMemo(() => groupDocsByHash(sortedActiveDocs), [sortedActiveDocs]);

  // Sorted and grouped removed documents
  // eslint-disable-next-line
  const sortedRemovedDocs = useMemo(() => sortDocs(removedDocs), [removedDocs]);
  const groupedRemovedDocs = useMemo(() => groupDocsByHash(sortedRemovedDocs), [sortedRemovedDocs]);

  const duplicatesActive = Object.values(groupedActiveDocs).filter((group: any) => group.length > 1);
  const duplicatesRemoved = Object.values(groupedRemovedDocs).filter((group: any) => group.length > 1);

  const showActive = () => {
    setDocsTab('active');
  };

  const showRemoved = () => {
    setDocsTab('removed');
  };

  const MB_150_IN_BYTES = 150 * 1e6;
  const sum = activeDocs?.docs?.reduce((acc: any, doc: any) => acc + (doc?.file_size || 0), 0) || 0;
  const prohibited_docs_length = activeDocs?.docs?.filter((doc: any) => {
    return doc?.file_size > MB_150_IN_BYTES;
  }).length;
  const at_least_one_prohibited = prohibited_docs_length >= 1;
  const only_1_prohibited = prohibited_docs_length === 1;

  const GB_2_IN_BYTES = 2 * 1e9;
  const size_limit_exceeded = sum > GB_2_IN_BYTES;
  const count_limit_exceeded = (activeDocs?.docs?.length || 0) > 1000;

  const unsupported_docs_length = sortedActiveDocs.filter((doc: ExtendedChronosDoc) => doc.isUnsupported).length;
  const unsupported_docs = sortedActiveDocs.filter((doc: ExtendedChronosDoc) => doc.isUnsupported);

  const message = prohibited_docs_length
    ? `${prohibited_docs_length} file${only_1_prohibited ? '' : 's'} exceed${
        only_1_prohibited ? 's' : ''
      } 150MB file size limit`
    : size_limit_exceeded && count_limit_exceeded
    ? 'Exceeded 2GB file size and 1000 file count limits.'
    : size_limit_exceeded
    ? 'Exceeded 2GB file size limit.'
    : count_limit_exceeded
    ? 'Exceeded 1000 file count limit.'
    : unsupported_docs_length
    ? `${unsupported_docs_length} unsupported file${unsupported_docs_length === 1 ? '' : 's'} (zip/rar).`
    : '';

  return (
    <div className="LotaGrotesque flex flex-row relative overflow-auto w-full text-white">
      <div className="font-sans flex flex-col pl-8 w-full items-start justify-start">
        {isLoadingNewCase ? (
          <div className="w-full h-full flex flex-col items-center justify-start text-black pt-14">
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer mt-6"
              onClick={handleClickBack}
            />
            <div className="text-black text-3xl not-italic font-bold w-full">{caseName}</div>
            <div className="" style={{ width: '665px' }}>
              <div className="text-black text-xl not-italic font-bold mt-4">
                Additional Files {activeDocs?.length ? `(${activeDocs?.length})` : ''}
              </div>
              {!!message && <div className="text-red-700 font-semibold italic">{message}</div>}
              <FileUploader
                uploading={posting}
                onDrop={onDrop}
                expectedUploads={expectedUploads}
                successfulUploads={successfulUploads}
              />
              <div className="w-full flex flex-column">
                {isLoadingDocuments && <StageSpinner className="m-auto" size={25} color={'#4161FF'} />}
                {responseDocs?.docs && responseDocs?.docs.length > 0 && (
                  <div className="w-full mr-16">
                    <div className="flex flex-row mb-3">
                      <div
                        className={`px-6 py-3   ${
                          docsTab === 'active' ? 'border-b-4 text-blue-600 border-blue-600' : 'text-gray-600'
                        } cursor-pointer`}
                        onClick={showActive}
                      >
                        Active
                      </div>
                      <div
                        className={`px-6 py-3   ${
                          docsTab === 'removed' ? 'border-b-4 text-blue-600 border-blue-600' : 'text-gray-600'
                        } cursor-pointer`}
                        onClick={showRemoved}
                      >
                        Removed
                      </div>
                    </div>
                    {(docsTab === 'active' ? sortedActiveDocs : sortedRemovedDocs)?.map((doc: any, idx: number) => {
                      const file_size_exceeded = doc.file_size > MB_150_IN_BYTES;
                      const isDuplicate = (docsTab === 'active' ? duplicatesActive : duplicatesRemoved).some(
                        (group: any) => {
                          if (!doc.file_hash) return false;
                          return group.some((dupDoc: any) => dupDoc.file_hash === doc.file_hash);
                        },
                      );
                      const isUnsupported = doc.isUnsupported;
                      // Apply a orange border if it's a duplicate
                      const docStyle = isDuplicate
                        ? { border: '2px solid orange' }
                        : isUnsupported
                        ? { border: '2px solid red' }
                        : {};
                      return (
                        <div
                          className={`bg-white mb-3 p-3 w-full rounded-lg h-20 flex items-center ${
                            file_size_exceeded ? ' border border-red-500' : ''
                          } ${isDuplicate ? ' border border-orange-500' : ''} ${
                            isUnsupported ? 'border border-red-500' : ''
                          }`}
                          key={doc._id}
                          style={docStyle}
                        >
                          <div
                            className="w-full flex items-center justify-between"
                            style={{ color: 'var(--dark-blue-80, #373D68)' }}
                          >
                            <div
                              className={`w-1/3 shrink-0 truncate ${
                                file_size_exceeded ? ' text-red-700 font-semibold italic' : ''
                              } ${isDuplicate ? '  text-orange-700 font-semibold italic' : ''} ${
                                isUnsupported ? ' text-red-700 font-semibold italic' : ''
                              }`}
                            >
                              {getFileNameWithExtension(doc?.file_name)}
                            </div>
                            <div className="w-1/3 shrink-0 flex items-center justify-center">
                              <div
                                className={`flex w-13 h-7 justify-center items-center py-3 rounded-3xl text-sm not-italic font-bold leading-4 uppercase px-2 bg-blue-50 text-blue-400 ${
                                  file_size_exceeded ? 'text-red-600 bg-red-200' : ''
                                }  ${isDuplicate ? '  text-orange-600 bg-orange-200' : ''} ${
                                  isUnsupported ? ' text-red-600 bg-red-200' : ''
                                }`}
                              >
                                {doc.file_extension ? doc.file_extension.toUpperCase() : '---'}
                              </div>
                            </div>
                            <div
                              className={`text-sm not-italic font-medium w-22 flex flex-row items-center justify-center ${
                                file_size_exceeded ? 'text-red-700 font-semibold italic' : ''
                              }  ${isDuplicate ? ' text-orange-700 font-semibold italic' : ''} ${
                                isUnsupported ? ' text-red-700 font-semibold italic' : ''
                              }`}
                            >
                              {file_size_exceeded && !isDuplicate && (
                                <>
                                  <FontAwesomeIcon
                                    data-tooltip-id={`tooltip-exceeded-file-size-${idx}`}
                                    icon={faInfoCircle}
                                    data-tooltip-content="File size too large for processing"
                                    className=" text-red-700 ml-2 text-sm cursor-pointer mr-3"
                                    style={{ width: '16px' }}
                                  />
                                  <Tooltip
                                    id={`tooltip-exceeded-file-size-${idx}`}
                                    className="z-10"
                                    style={{
                                      display: 'flex',
                                      width: '300px',
                                      padding: '16px',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: '8px',
                                      borderRadius: '16px',
                                      background: '#FFF',
                                      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                                      color: 'var(--colors-primary-slate-600, #455468)',
                                      fontFamily: 'Lota Grotesque',
                                      fontSize: '12px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: '20px',
                                      letterSpacing: '-0.3px',
                                    }}
                                  />
                                </>
                              )}
                              {isDuplicate && 'Duplicate'}
                              {isUnsupported && 'Unsupported'}
                              {!isDuplicate &&
                                !isUnsupported &&
                                (doc.file_size ? `${niceBytes(doc.file_size)}` : '---')}
                            </div>
                            {docsTab === 'active' ? (
                              <div
                                className={`flex justify-center items-center px-2 py-3 rounded-lg ml-auto w-24 not-italic shrink-0 font-bold leading-6 cursor-pointer bg-blue-100 text-blue-600 ${
                                  file_size_exceeded ? 'text-red-600 bg-red-200' : ''
                                }  ${isDuplicate ? ' text-orange-700 bg-orange-200 font-semibold italic' : ''} ${
                                  isUnsupported ? ' text-red-600 bg-red-200' : ''
                                }`}
                                onClick={() => handleRemoveDoc(doc.doc_id)}
                              >
                                {docRemoving === doc.doc_id ? (
                                  <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
                                ) : (
                                  'Remove'
                                )}
                              </div>
                            ) : (
                              <div className="w-24"></div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!isLoadingNewCase && (
        <div className="w-full flex flex-row h-full sticky top-0 ml-7">
          <button
            style={{ bottom: '10px' }}
            className="absolute cursor-pointer flex flex-row justify-center items-center  h-12 w-96 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:opacity-20 shrink-0 text-center text-base not-italic font-bold leading-6 text-white disabled:cursor-not-allowed px-6 py-4"
            onClick={handleUpdateCase}
            disabled={
              !!duplicatesActive.length ||
              at_least_one_prohibited ||
              isLoadingUpdateCase ||
              posting ||
              !responseDocs?.docs?.length ||
              size_limit_exceeded ||
              count_limit_exceeded ||
              unsupported_docs.length > 0 // Disable if there are unsupported files
            }
          >
            {isLoadingUpdateCase ? <StageSpinner className="m-auto" size={25} color={'white'} /> : 'Next'}
          </button>
        </div>
      )}
    </div>
  );
};

export default DocAdder;
