import { FilterFn } from '@tanstack/react-table';
import { ChronosChronologyEvent, FilterOption } from 'types';

export interface FilterFns {
  stringMatchVerified: FilterFn<ChronosChronologyEvent>;
}

const filterFns: FilterFns = {
  stringMatchVerified: (row, columnId, filterValue: FilterOption[]) => {
    if (!filterValue.length) return true;
    const rowValue = row.getValue<string>('verified');
    return filterValue.map((values) => values.value).includes(rowValue);
  },
};

export default filterFns;
