import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

// Assuming matterId is a string and fetchConfigGET is your fetch configuration for GET requests
const useGetMatter = (matterId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();
  const getMatterData = async () => {
    const response = await fetch(`${APIBaseChronos}/client/case/matter/${matterId}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching matter data failed');
    }
    return response.json();
  };

  const {
    isFetching: isLoadingMatter,
    data: responseMatterData,
    refetch: refetchMatterData,
    error,
  } = useQuery(['matterDataResult', matterId], getMatterData, {
    cacheTime: 0,
    enabled: !!matterId, // Only run the query if the matterId is provided
  });

  return {
    isLoadingMatter,
    responseMatterData,
    refetchMatterData,
    error,
  };
};

export default useGetMatter;
