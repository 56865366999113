import { StageSpinner } from 'react-spinners-kit';

interface CreateMatterButtonProps {
  isLoading: boolean;
  isDisabled: boolean;
  onClick: () => void;
  text: string;
}

const CreateMatterButton = ({ isLoading, isDisabled, onClick, text }: CreateMatterButtonProps) => {
  return (
    <button
      style={{ bottom: '10px' }}
      className="absolute cursor-pointer flex flex-row justify-center items-center  h-12 w-96 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:opacity-20 shrink-0 text-center text-base not-italic font-bold leading-6 text-white disabled:cursor-not-allowed px-6 py-4"
      onClick={onClick}
      disabled={isDisabled}
    >
      {isLoading ? <StageSpinner color={'white'} size={25} /> : text}
    </button>
  );
};

export default CreateMatterButton;
