import React, { useState } from 'react';

import { faArrowRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  SortingState,
  flexRender,
  Row as TableRow,
} from '@tanstack/react-table';
import { ChronosFact } from 'types';

interface FactsTableProps {
  data: ChronosFact[];
  columns: ColumnDef<ChronosFact>[];
  handleViewSingleFact: (factId: string) => void;
}

interface RowProps {
  row: TableRow<ChronosFact>;
  rowLength: number;
  onClick: (id: string) => void;
}

// Custom row component
const Row = ({ row, rowLength, onClick }: RowProps) => {
  const [isLoading, setLoading] = useState(false);

  const handleClick = (event_id: string) => {
    setLoading(true);
    onClick(event_id);
  };

  return (
    <tr
      id={row.id}
      key={row.id}
      className={`h-24 outer-hover ${row.index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}
      style={{
        borderBottom: row.index !== rowLength ? '1px solid #c8cad7' : 'none',
      }}
    >
      {row.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          className="pl-3 pr-5"
          {...{
            style: {
              width: cell.column.getSize(),
              height: '100px',
              minHeight: '100px',
              maxHeight: '100px',
              maxWidth: '600px',
            },
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
      <td
        onClick={() => handleClick(row.original.event_id)}
        className={`w-12 justify-center items-center pl-6 hover:cursor-pointer ${
          row.index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'
        }`}
      >
        {!isLoading ? (
          <FontAwesomeIcon icon={faArrowRight} className="text-blue-600 w-5 h-5 fa-arrow-bounce" />
        ) : (
          <FontAwesomeIcon icon={faCircleNotch} className="text-blue-600 w-5 h-5 fa-spin" />
        )}
      </td>
    </tr>
  );
};

const FactsTable: React.FC<FactsTableProps> = ({ data, columns, handleViewSingleFact }) => {
  // Set up Table
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'date_of_subject',
      desc: false,
    },
  ]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getRowId: (row) => row.event_id,
  });

  return (
    <div className="relative inline-block w-full rounded-md">
      <div className="rounded-md absolute top-0 left-0 right-0 bottom-0 pointer-events-none"></div>
      <table className="w-full overflow-scroll border-collapse">
        <thead className="h-12 sticky top-0 bg-slate-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="not-italic font-medium leading-5 relative"
                  colSpan={header.colSpan}
                  style={{
                    color: 'var(--colors-primary-slate-400, #8897AE)',
                    width: header.getSize(),
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
              <th className="not-italic font-medium leading-5 relative"></th>
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: TableRow<ChronosFact>) => (
            <Row
              key={row.id}
              row={row}
              rowLength={table.getRowModel().rows.length - 1}
              onClick={handleViewSingleFact}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FactsTable;
