import React, { useState } from 'react';

import {
  faBookAtlas,
  faBookOpen,
  faChartColumn,
  faDoorOpen,
  faEnvelope,
  faGear,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLogoutFunction } from '@propelauth/react';
import { settingsHost } from 'api/settings_hosts';
import { ReactComponent as ProjectIcon } from 'assets/project.svg';
import { useUserContext } from 'Contexts/User';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

import * as styledComponents from './styles';

const SideMenu: React.FC = () => {
  const [compressed, setCompressed] = useState<boolean>(true);
  const logoutFn = useLogoutFunction();
  const { user } = useUserContext();

  const handleClickLogout = (): void => {
    logoutFn(true);
    Swal.fire({
      title: 'Successfully logged out',
      text: 'Hope to see you soon',
      showConfirmButton: false,
      timer: 3000,
      background: '#FFFFFF',
      color: '#081D57',
    });
  };

  const handleClickSettings = (): void => {
    window.open(settingsHost, '_self');
  };

  interface NavItemProps {
    title: string;
    link: string;
    compressed: boolean;
    icon: JSX.Element;
  }

  const NavItem = ({ title, link, compressed, icon }: NavItemProps) => {
    return (
      <NavLink
        to={link}
        className={`LotaGrotesque not-italic font-normal text-base leading-5 text-white cursor-pointer h-11 mb-6 flex flex-row items-center ${
          !compressed ? 'w-60' : ''
        }`}
      >
        {icon}
        {!compressed && title}
      </NavLink>
    );
  };

  return (
    <styledComponents.ContainerPanel compressed={compressed}>
      <styledComponents.ContainerMenu className="w-full h-full">
        <div
          className="not-italic font-semibold text-3xl text-white cursor-pointer mb-10"
          onClick={() => setCompressed(!compressed)}
        >
          {compressed ? 'w' : 'wexler.ai'}
        </div>
        <>
          <NavItem
            title="Chronos"
            compressed={compressed}
            link="app/chronos/matters"
            icon={<ProjectIcon className="mr-3 w-5 text-white" />}
          />
          <NavItem
            title="User Guide"
            compressed={compressed}
            link="app/chronos/guide"
            icon={<FontAwesomeIcon icon={faBookAtlas} className="mr-3 w-5 text-white" />}
          />
          <NavItem
            title="Case Synopsis"
            compressed={compressed}
            link="app/chronos/case-synopsis"
            icon={<FontAwesomeIcon icon={faBookOpen} className="mr-3 w-5 text-white" />}
          />

          {user?.metadata && user?.metadata.analyticsAdmin === 'true' && (
            <NavItem
              title="Usage Dashboard"
              compressed={compressed}
              link="app/chronos/usage-dashboard"
              icon={<FontAwesomeIcon icon={faChartColumn} className="mr-3 w-5 text-white" />}
            />
          )}
        </>
        <a
          href="mailto:support@wexler.ai"
          className="LotaGrotesque not-italic font-normal text-base text-white cursor-pointer h-11 flex flex-row items-center mt-auto"
        >
          <FontAwesomeIcon icon={faEnvelope} className="mr-3 w-5 text-white" />
          {!compressed && 'support@wexler.ai'}
        </a>
        <a
          href="tel:+442080409060"
          className={`LotaGrotesque not-italic font-normal text-base text-white cursor-pointer h-11 flex flex-row items-center ${
            !compressed ? 'w-60' : ''
          }`}
        >
          <FontAwesomeIcon icon={faPhone} className="mr-3 w-5 text-white" />
          {!compressed && '+44 20 80 40 90 60'}
        </a>

        <div
          className={`LotaGrotesque not-italic font-normal text-base text-white cursor-pointer h-11 flex flex-row items-center ${
            !compressed ? 'w-60' : ''
          }`}
          onClick={handleClickSettings}
        >
          <FontAwesomeIcon icon={faGear} className="mr-3 w-5 text-white" />
          {!compressed && 'Settings'}
        </div>
        <div
          className={`LotaGrotesque not-italic font-normal text-base text-white cursor-pointer h-11 flex flex-row items-center ${
            !compressed ? 'w-60' : ''
          }`}
          onClick={handleClickLogout}
        >
          <FontAwesomeIcon icon={faDoorOpen} className="mr-3 w-5 text-white" />
          {!compressed && 'Logout'}
        </div>
      </styledComponents.ContainerMenu>
    </styledComponents.ContainerPanel>
  );
};

export default React.memo(SideMenu);
