import React, { Dispatch, SetStateAction, useRef, useState } from 'react';

import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { TooltipStylesTable } from 'constants/styles';
import parse from 'html-react-parser';
import { useQuery } from 'react-query';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
interface PlainTextContentProps {
  html: string;
  maxLength: number;
}

const PlainTextContent: React.FC<PlainTextContentProps> = ({ html, maxLength = 100 }) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  let textContents = Array.from(doc.body.childNodes)
    .map((node) => node.textContent?.trim()) // Extract the text content of each node
    .filter((text): text is string => Boolean(text)); // Remove any null or undefined text content

  // Join the text content, separating each with a comma
  let joinedText = textContents.join(', ');

  // If the text exceeds the maxLength, truncate and add an ellipsis
  if (joinedText.length > maxLength) {
    joinedText = `${joinedText.substring(0, maxLength - 3)}...`;
  }

  return <p className="break-all">{joinedText}</p>;
};

interface TextInputCellProps {
  entryId: string;
  entryFieldKey: string;
  entryFieldValue: string;
  endpointToUpdate: string;
  editable?: boolean;
  className?: string;
  html?: boolean;
  setIsDateDirty?: Dispatch<SetStateAction<boolean>>;
  callback?: () => void;
}

const TextInputCell = ({
  entryId,
  entryFieldKey,
  entryFieldValue,
  endpointToUpdate,
  editable,
  className,
  html,
  setIsDateDirty,
  callback,
}: TextInputCellProps) => {
  const [editMode, setEditMode] = useState(false);
  const [currentFieldValue, setCurrentFieldValue] = useState(entryFieldValue || '');
  const { getFetchConfig } = useGetFetchConfig();

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = '38px'; // Reset the height to its initial value
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const { isFetching: isLoadingUpdateFieldValue, refetch: refetchUpdateFieldValue } = useQuery(
    ['updateFieldValue', entryId, entryFieldKey],
    () => {
      setEditMode(false);
      const fetchConfig = getFetchConfig({ method: 'PUT', data: { [entryFieldKey]: currentFieldValue } });
      return fetch(endpointToUpdate, fetchConfig)
        .then((res) => {
          if (entryFieldKey === 'date_of_subject_text' && setIsDateDirty) {
            setIsDateDirty(true);
          }
          callback && callback();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          Swal.fire({
            title: 'Error on update',
            text: 'There was an error on updating the fields. Please try again later.',
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const togleEditMode = () => {
    if (!editMode) {
      setEditMode(true);
      setTimeout(() => {
        autoResize(textAreaRef);
      }, 0);
    } else {
      refetchUpdateFieldValue();
    }
  };

  const handleChangeFieldValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentFieldValue(e.target.value);
    autoResize(textAreaRef);
  };

  return (
    <div className={`flex flex-row items-center justify-start w-full ${className}`}>
      {editMode === true ? (
        <>
          <textarea
            ref={textAreaRef}
            className={`rounded-md pl-2 resize-none overflow-hidden w-full`}
            onChange={handleChangeFieldValue}
            onClick={(e) => e.stopPropagation()}
            value={currentFieldValue}
            style={{
              outline: 'none',
              border: '1px solid rgba(0,0,0,0.1)',
              minWidth: '100px',
              width: '80%',
              height: '38px',
            }}
          />
          <FontAwesomeIcon
            onClick={(e) => {
              e.stopPropagation();
              togleEditMode();
            }}
            icon={faCheck}
            className="text-gray-700 ml-2 cursor-pointer"
          />
        </>
      ) : isLoadingUpdateFieldValue ? (
        <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
      ) : (
        <>
          <div>
            {html ? (
              <>
                <div
                  data-tooltip-id={`source-text-tooltip-${entryId}`}
                  className="cursor-pointer"
                  style={{ backgroundColor: 'white !important' }}
                >
                  <PlainTextContent html={currentFieldValue} maxLength={100} />
                </div>
                <Tooltip id={`source-text-tooltip-${entryId}`} style={TooltipStylesTable}>
                  {currentFieldValue ? parse(currentFieldValue) : null}
                </Tooltip>
              </>
            ) : (
              currentFieldValue
            )}
          </div>
          {editable && (
            <FontAwesomeIcon
              onClick={(e) => {
                e.stopPropagation();
                togleEditMode();
              }}
              icon={faPencil}
              className="text-gray-700 ml-2 cursor-pointer"
            />
          )}
        </>
      )}
    </div>
  );
};

export default TextInputCell;
