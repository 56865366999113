import React, { useEffect, useMemo, useState } from 'react';

import { faAdd, faChevronLeft, faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrgMemberInfo, useAuthInfo } from '@propelauth/react';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useUserContext } from 'Contexts/User';
import Masonry from 'react-responsive-masonry';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

import MatterItemComponent from './components/MatterItemComponent';
import useMatters from './hooks/useMatters';
import { MatterItem, QueryParams } from './types';

const roles = {
  ADMIN: 'Admin',
};
const MattersExplorer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const orgs = authInfo.orgHelper?.getOrgs();
  const asignedOrg = orgs?.length && (orgs[0] as OrgMemberInfo);
  const assignedRole = asignedOrg && asignedOrg?.userAssignedRole;
  const isAdmin = assignedRole === roles['ADMIN'];
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.orgSuperAdmin === 'true';
  const { getFetchConfig } = useGetFetchConfig();
  const searchTextQuery = searchParams.get('searchText') || '';
  const catchwordsQuery = searchParams.get('catchwords') || '';
  const dateFromQuery = searchParams.get('dateFrom') || '';
  const dateToQuery = searchParams.get('dateTo') || '';
  const sortByQuery = searchParams.get('sortBy') || 'score_desc';
  const pageQuery = parseInt(searchParams.get('page') || '1') || 1;
  const advancedFiltersActive = false;
  const [searchText, setSearchText] = useState(searchTextQuery || '');
  const nPages = 1;

  const queryParams: QueryParams = useMemo(
    () => ({
      search: searchTextQuery,
      page: pageQuery,
      catchwords: catchwordsQuery,
      dateFrom: dateFromQuery,
      dateTo: dateToQuery,
      sortBy: sortByQuery,
    }),
    [searchTextQuery, pageQuery, catchwordsQuery, dateFromQuery, dateToQuery, sortByQuery],
  );

  const { isLoading, responseData, refetch } = useMatters(queryParams);

  useEffect(() => {
    refetch();
  }, [refetch, queryParams]);

  useEffect(() => {
    setSearchText(searchTextQuery || '');
    refetch();
    // eslint-disable-next-line
  }, [searchTextQuery, pageQuery, catchwordsQuery, dateFromQuery, dateToQuery, sortByQuery]);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClickMatterItem = (id: string) => {
    navigate(`/app/chronos/explore?matterId=${id}`);
  };

  const handleClickEditMatter = (id: string) => {
    navigate(`/app/chronos/matter-editor/${id}`);
  };

  const handleGoToPreviousPage = () => {
    if (pageQuery > 1) {
      if (advancedFiltersActive) {
        navigate(
          `/app/explore?searchText=${searchTextQuery}&page=${
            pageQuery - 1
          }&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&sortBy=${sortByQuery}`,
        );
      } else {
        navigate(`/app/explore?searchText=${searchText}&page=${pageQuery - 1}`);
      }
    }
  };

  const handleGoToNextPage = () => {
    if (pageQuery < nPages) {
      if (advancedFiltersActive) {
        navigate(
          `/app/chronos/explore?searchText=${searchTextQuery}&page=${
            pageQuery + 1
          }&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&sortBy=${sortByQuery}`,
        );
      } else {
        navigate(`/app/chronos/explore?searchText=${searchText}&page=${pageQuery + 1}`);
      }
    }
  };

  const goToNewMatter = () => {
    navigate(`/app/chronos/matter-creator`);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      refetch();
    }
  };

  const handleClickDelete = (matterIdToDelete: string) => {
    Swal.fire({
      title: '',
      text: 'Deleting matters is irreversible. Do you want to proceed?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {
        const fetchConfig = getFetchConfig({ method: 'DELETE' });
        return fetch(`${APIBaseChronos}/client/matter/${matterIdToDelete}`, fetchConfig)
          .then((res) => {
            return res.json();
          })
          .then((resJson) => {
            if (resJson.success) {
              Swal.fire({
                title: '',
                text: 'Matter deleted successfully',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
              refetch();
            } else if (resJson.notCreator) {
              Swal.fire({
                title: 'Not authorized',
                text: 'You are not the creator of this matter or you do not have the require permissions',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
            } else {
              Swal.fire({
                title: '',
                text: 'Error deleting the matter. Try again later',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: '',
              text: 'Error deleting the matter. Try again later',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000,
            });
            console.error(err);
          });
      }
    });
  };

  return (
    <div className=" flex flex-col w-full h-screen">
      <div className="LotaGrotesque font-sans flex flex-col md:flex-row items-start justify-start text-white h--full overflow-y-auto">
        <div className="flex flex-col items-start justify-start w-full h-full">
          <div className="flex flex-row w-full flex-wrap mt-11">
            <div className="flex flex-wrap flex-row items-start justify-start w-full pl-4">
              {!isLoading && responseData && (
                <div className="mb-4 w-full pr-10 pl-3 flex flex-col">
                  <div className="text-gray-900 text-4xl not-italic font-semibold mb-4">Matters</div>
                  {false && (
                    <div
                      className="relative  bg-white"
                      style={{ width: '549px', borderRadius: '16px', border: '1px solid var(--black-10, #E7E7E7)' }}
                    >
                      <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-5 text-gray-400" />
                      {
                        <input
                          className="pl-10 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 bg-white"
                          style={{ borderRadius: '16px', height: '56px' }}
                          onChange={handleChangeSearchInput}
                          onKeyDown={handleKeyDown}
                          value={searchText}
                          placeholder={'Search matters'}
                        />
                      }
                    </div>
                  )}
                  {(isAdmin || isSuperAdmin) && (
                    <button
                      className="cursor-pointer flex flex-row justify-center items-center  h-12 mt-4  px-4 bg-blue-600  hover:bg-blue-500 disabled:bg-gray-500 shrink-0"
                      onClick={goToNewMatter}
                      style={{ borderRadius: '16px', width: '164px', height: '56px' }}
                    >
                      <FontAwesomeIcon icon={faAdd} className="text-white mr-2" />
                      New matter
                    </button>
                  )}
                </div>
              )}
              {!isLoading && !responseData?.items?.length && (
                <div className="text-xl text-gray-900 mb-4 w-full px-10 ml-0.5">No items found</div>
              )}
              {isLoading && (
                <div className="w-full h-full flex items-center justify-center">
                  <StageSpinner className="m-auto" size={25} color={'#081D57'} />
                </div>
              )}
              <div className="pr-7 w-full flex flex-row flex-wrap">
                <Masonry columnsCount={2}>
                  {!isLoading &&
                    responseData?.items?.map((item: MatterItem, idxItem: number) => {
                      const isCreator = item.created_by === authInfo.user?.userId;
                      return (
                        <div key={idxItem} className="p-3">
                          <MatterItemComponent
                            item={item}
                            onEdit={handleClickEditMatter}
                            onDelete={handleClickDelete}
                            onView={handleClickMatterItem}
                            isCreator={isCreator}
                            isSuperAdmin={isSuperAdmin}
                          />
                        </div>
                      );
                    })}
                </Masonry>
              </div>
              {nPages > 1 && (
                <div className="w-full flex flex-row items-center justify-center text-black my-10">
                  <button
                    className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                    disabled={pageQuery <= 1}
                    onClick={handleGoToPreviousPage}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <button className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer">
                    {pageQuery}
                  </button>
                  <button
                    className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                    disabled={pageQuery >= nPages}
                    onClick={handleGoToNextPage}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MattersExplorer;
