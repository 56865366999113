import React from 'react';

import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '@ramonak/react-progress-bar';
import { TooltipStyles } from 'constants/styles';
import { useDropzone } from 'react-dropzone';
import { Tooltip } from 'react-tooltip';

interface FileUploaderProps {
  uploading: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  successfulUploads: number;
  expectedUploads: number;
}

const FileUploader: React.FC<FileUploaderProps> = ({ uploading, onDrop, successfulUploads, expectedUploads }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      data-testId="file-uploader"
      className="w-108 h-36 flex items-center justify-center mb-3 cursor-pointer rounded-2xl mt-4 mr-16 border border-dashed border-blue-400 bg-slate-100"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {uploading && (
        <div className="flex flex-col">
          <div className="text-gray-900 text-center text-base not-italic font-bold leading-6 mb-4">
            Uploaded {successfulUploads} of {expectedUploads} files
          </div>
          <ProgressBar
            completed={(100 * successfulUploads) / expectedUploads}
            customLabel={''}
            bgColor={'#4161FF'}
            height={'6px'}
            isLabelVisible={false}
          />
        </div>
      )}
      {!uploading &&
        (isDragActive ? (
          <p className="text-blue-500">Drop the files here ...</p>
        ) : (
          <div>
            <button
              data-tooltip-id="agreed-tooltip"
              data-tooltip-target="tooltip-hover"
              data-tooltip-trigger="hover"
              data-tooltip-content="Supported file types: EML, MHT, DocX, Doc, PDF, and Spreadsheet files (more coming soon). Max file size is 150MB."
              className="cursor-pointer flex flex-row justify-center items-center h-12 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-500 shrink-0 ml-2 text-center text-base not-italic font-bold leading-6 text-white px-6 py-4"
            >
              <FontAwesomeIcon icon={faFolderPlus} className="text-white mr-2" />
              Upload Files
            </button>
            <Tooltip id="agreed-tooltip" style={TooltipStyles} />

            <div className="text-center text-sm not-italic font-bold leading-4 mt-4 text-blue-600">Or drag here</div>
          </div>
        ))}
    </div>
  );
};

export default FileUploader;
