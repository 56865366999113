import { APIBaseChronos } from 'api/hosts'; // Ensure this path is correct
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

import { MattersResponse, QueryParams } from '../types';
const useMatters = (queryParams: QueryParams) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const queryString = new URLSearchParams(queryParams as any).toString();

  const fetchMatters = async (): Promise<MattersResponse> => {
    const response = await fetch(`${APIBaseChronos}/client/case/matter?${queryString}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching matters failed');
    }
    return response.json();
  };

  const {
    isLoading,
    data: responseData,
    refetch,
    error,
  } = useQuery<MattersResponse, Error>(['matters', queryString], fetchMatters, {
    cacheTime: 0,
    enabled: !!queryString,
  });

  return {
    isLoading,
    responseData,
    refetch,
    error,
  };
};

export default useMatters;
