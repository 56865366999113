import React, { useEffect, useRef, useState } from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Accordion = ({
  title,
  content,
  index,
  icon,
  id,
}: {
  title: string | React.ReactNode;
  content: React.ReactNode;
  index?: number;
  icon?: React.ReactNode;
  id?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setMaxHeight(isOpen ? '0px' : `${contentRef.current?.scrollHeight}px`);
  };

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, [isOpen, contentRef.current?.scrollHeight]);

  return (
    <div
      className={`bg-gray-50 border-b p-4 ${index === 0 ? 'rounded-t-md' : ''} ${index === 3 ? 'rounded-b-md' : ''} `}
    >
      <div id={id} className="flex justify-between items-center hover:cursor-pointer" onClick={toggleAccordion}>
        <h3 className="font-semibold text-gray-800 pr-2">{title}</h3>
        {icon ? (
          icon
        ) : (
          <FontAwesomeIcon
            icon={faChevronDown}
            className="text-gray-500 transition-transform duration-300"
            style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        )}
      </div>
      <div
        ref={contentRef}
        className={`transition-max-height duration-500 ease-in-out overflow-hidden`}
        style={{ maxHeight: maxHeight }}
      >
        {content}
      </div>
    </div>
  );
};

export default Accordion;
