import React, { useMemo } from 'react';

interface DocumentsProcessedInfoProps {
  processedCount: number;
  totalDocuments: number;
}

const DocumentsProcessedInfo: React.FC<DocumentsProcessedInfoProps> = ({ processedCount, totalDocuments }) => {
  const showRemainingDocumentsMessage = useMemo(
    () => totalDocuments > 0 && processedCount / totalDocuments !== 1,
    [processedCount, totalDocuments],
  );
  return (
    <div>
      <p className="text-gray-800 not-italic font-semibold">
        <b>
          {processedCount}/{totalDocuments}
        </b>{' '}
        documents processed
      </p>
      <p className="text-gray-500 not-italic">
        {showRemainingDocumentsMessage && 'Please add facts manually to the remaining documents'}
      </p>
    </div>
  );
};

export default React.memo(DocumentsProcessedInfo);
